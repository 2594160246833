// CheckoutStepper.js

import React, { useState, useContext } from "react";
import { CheckoutContext } from "../../CheckOutContext";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import NavBar from "../../Composant/NavBar";
import Button from "@mui/material/Button";
import { showToast } from "../../Composant/toastNotifications";
const steps = ["Adresse de livraison", "Détails de paiement", "Récapitulatif"];

export default function CheckoutStepper() {
  const { activeStep, setActiveStep } = useContext(CheckoutContext);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Processus de commande
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {getStepContent(activeStep, handleNext, handleBack)}
            {activeStep !== steps.length && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Retour
                  </Button>
                )}
              </div>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </React.Fragment>
  );
}

function getStepContent(step, handleNext, handleBack) {
  switch (step) {
    case 0:
      return <AddressForm handleNext={handleNext} />;
    case 1:
      return <PaymentForm handleNext={handleNext} handleBack={handleBack} />;
    case 2:
      return <Review handleNext={handleNext} handleBack={handleBack} />;
    default: {
      // alert("finito");
      showToast('success', 'Finalisation de la commande !');
      window.location.href="/home"
    }
  }
}
