import { React } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FirstPage from "./component/Pages/FirstPage";
import About from "./component/Pages/About";
import Books from "./component/Pages/Books";
import Home from "./component/Pages/Home";
import Book from "./component/Pages/Book";
import Login from "./component/Pages/Login";
import SignUp from "./component/Pages/Register";
import FormHistoire from "./component/Pages/FormHistoire";
import Cart from "./component/Pages/cart";
import CheckOutPage from "./component/Pages/CheckOut/CheckOutStepper";
// import Cart2 from "./component/Pages/cartTest"
import { BookProvider } from "./component/BookContext";
import { UserProvider } from "./component/UserContext";
import { CheckoutProvider } from "./component/CheckOutContext";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const stripePromise = loadStripe("pk_test_51OM9PBFSVL8rHOWvhjqoPnxiXVj7ZWrh3hrxiAOhfH9qKXHdbLvaTUKAP1u8F0FzH3LfiJh4Y5SCJHe5Lz0dhbMv00g4jM3moE");

function App() {
  return (
    <Elements stripe={stripePromise}>
      <UserProvider>
        <BookProvider>
          <CheckoutProvider>
            <Router>
              <div className="App">
                <div className="principal">
                  <Routes>
                    <Route path="/" element={<FirstPage />} />
                    <Route path="/About" element={<About />} />
                    <Route path="/Books" element={<Books />} />
                    <Route path="/Home" element={<Home />} />
                    <Route path="/:id" element={<Book />} />
                    {/* <Route path="/Paiment" element={<Paiment />} /> */}
                    <Route path="/Login" element={<Login />} />
                    <Route path="/SignUp" element={<SignUp />} />
                    {/* <Route path="/FormHistoire" element={<FormHistoire />} /> */}
                    <Route path="/Contact" element={<FirstPage />} />
                    <Route path="/Cart" element={<Cart />} />
                    <Route path="/CheckOutPage" element={<CheckOutPage />} />
                    <Route path="*" element={<FirstPage />} />
                  </Routes>
                </div>
                <ToastContainer />
              </div>
            </Router>
          </CheckoutProvider>
        </BookProvider>
      </UserProvider>
    </Elements>
  );
}

export default App;
