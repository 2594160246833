import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { styled } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import axios from "axios";
const StyledSlider = styled(Slider)({
  ".slick-slide": {
    padding: "0 0px",
  },
  ".slick-list": {
    margin: "0 0px",
  },
});

const StyledSlide = styled("div")(({ theme, isActive }) => ({
  textAlign: "center",
  transition: "transform 0.8s ease-in-out",
  transform: isActive ? "scale(1)" : "scale(0.8)",
  opacity: isActive ? 1 : 0.5,
}));
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "180px",
  backgroundColor: "#FFFFFF",
  border: "solid 3px black",
  borderRadius: "30px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  position: "relative",
  [theme.breakpoints.down("485")]: { height: "120px" },
  [theme.breakpoints.down("370")]: { height: "140px" },
}));

const ImageSection = styled("div")({
  width: "100%",
  height: "100%",
  backgroundImage: 'url("/quots.png")',
  backgroundSize: "contain",
  backgroundPosition: "right",
  backgroundRepeat: "no-repeat",
  borderRadius: "30px",
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 1,
});

const TextSection = styled(Box)({
  position: "relative",
  width: "80%",
  marginLeft: "5px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2,
});

const Title = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: fontSize || 16,
  fontWeight: 700,
  lineHeight: lineH || "30px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("930")]: { fontSize: 14, lineHeight: "30px" },
  [theme.breakpoints.down("485")]: { fontSize: 11, lineHeight: "14px" },
}));
const Auteur = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: fontSize || 14,
  fontWeight: 200,
  lineHeight: lineH || "30px",
  textAlign: align || "left",
  marginRight: "10px",
  fontStyle: "italic",
  [theme.breakpoints.down("930")]: { fontSize: 12, lineHeight: "30px" },
  [theme.breakpoints.down("485")]: { fontSize: 8, lineHeight: "14px" },
}));

const CitaCard = (props) => {
  return (
    <Container>
      <ImageSection />
      <TextSection>
        <Title align={"center"}>
          {props.Cita.length > 100
            ? props.Cita.slice(0, 100) + "..."
            : props.Cita}
        </Title>
        <Auteur align={"center"}>{props.Auteur}</Auteur>
      </TextSection>
    </Container>
  );
};
const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  // const components = [
  //   "Un exemple n'est forcement un exemple a suivre.",
  //   "Le temps ne fait rien à l'affaire ; quand on est con, on est con.",
  //   "Il y a plusieurs façons d'être con, mais le con choisit toujours la pire.",
  //   "Rien n'est plus voluptueux pour un pas-con que d'être pris pour un con par un con.",
  //   "Il y a plusieurs façons d'être con, mais le con choisit toujours la pire.",
  // ];
  const [components, setComponents] = useState([]); // Utilisez useState pour stocker les citations

  useEffect(() => {
    // Fonction pour charger les citations de la base de données
    const loadCitacons = async () => {
      try {
        const response = await axios.get(
          "https://www.editionspointcon.fr/api/Utilisateur/Histoire/citacons"
        ); // Modifiez l'URL selon vos besoins
        setComponents(response.data); // Mettez à jour l'état des composants avec les données reçues
      } catch (error) {
        console.error("Error fetching citacons:", error);
      }
    };

    // Appeler la fonction au montage du composant
    loadCitacons();
  }, []); // Le tableau vide assure que l'effet ne s'exécute qu'une fois après le premier rendu

  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (_, next) => setActiveSlide(next),
    draggable: true, // Active le glissement avec la souris
    swipeToSlide: true, // Permet de glisser pour passer à la diapositive suivante
    speed: 1000, // Augmenter pour ralentir le changement de diapositive
    cssEase: "ease-in-out", // Fonction de timing pour une transition plus fluide
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1010,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledSlider {...settings}>
      {components.map((citation, index) => (
        <StyledSlide key={index} isActive={index === activeSlide}>
          <CitaCard Cita={citation.Contenu} Auteur={citation.Auteur} />{" "}
          {/* Assurez-vous que la structure des données correspond */}
        </StyledSlide>
      ))}
    </StyledSlider>
  );
};

export default Carousel;
