import React, { useState, useEffect } from "react";
import BookCorp from "../Composant/BookCorp";
import { useParams } from "react-router-dom";
import { useBooks } from "../useBook";
import axios from "axios";
export default function Book() {
  //************************************************* */
  let { id } = useParams();
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const response = await axios.get(
          `https://www.editionspointcon.fr/api/Utilisateur/Histoire/GetHistoire/?id=${id}`
        );
        
        console.log(response.data)
        // alert("yo : " + response.status);
        if (response.status === 200) {
          const data = {
            id: response.data.docs._id,
            Image:
              "https://www.editionspointcon.fr/" +
              response.data.docs.Images[response.data.docs.Images.length - 2], //response.data.docs.Images[0], // Prend la première image, ajustez selon les besoins
            Titre: response.data.docs.Titre,
            Description:
              response.data.docs.Chapitres.length > 0
                ? response.data.docs.Chapitres.length > 1
                  ? response.data.docs.Chapitres[0].slice(1, 4)
                  : response.data.docs.Chapitres[0].slice(0, 4)
                : [
                    "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer",
                  ],
            Biographie:
              response.data.docs.Resume.length > 0
                ? [response.data.docs.Resume.slice(0, 3)]
                : [
                    "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer",
                  ],
            Auteur: response.data.docs.Auteur,
            Categorie: response.data.docs.Category,
            Prix: response.data.docs.Prix,
            Note: response.data.docs.Note,
            Format: response.data.docs.Format,
            Disponibilite: response.data.docs.Disponibilite,
          };
          // console.log("data.docs "+data.docs)
          setBook(data); // Ajustez ici selon la structure de votre réponse
          setLoading(false);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du livre:", error);
        setLoading(false);
      }
    };

    fetchBook();
  }, [id]); // Récupère le livre chaque fois que l'ID change

  if (loading) {
    return <div>Chargement des détails du livre...</div>;
  }

  if (!book) {
    return <div>Livre introuvable</div>;
  }
  return <div>{book && <BookCorp Book={book} />}</div>;
}

//******************************************** */
