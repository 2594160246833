
import { useContext } from 'react';
import { UserContext } from './UserContext';

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUsers doit être utilisé à l\'intérieur d\'un UserProvider');
  }
  return context;
};
