// PaymentForm.js

import React, { useContext, useState } from "react";
import { CheckoutContext } from "../../CheckOutContext";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Typography, Grid, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Composant/toastNotifications";
import axios from "axios";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function PaymentForm({ handleNext }) {
  const { shippingDetails, paymentDetails, setPaymentDetails, setActiveStep } =
    useContext(CheckoutContext);
  const stripe = useStripe();
  const cart = useSelector((state) => state.cart);
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPaymentDetails({ ...paymentDetails, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }
    setIsProcessing(true);
    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error: intentError, clientSecret } = await axios
        .post(
          "https://www.editionspointcon.fr/api/Utilisateur/create-payment-intent",
          { cart: cart }
        )
        .then((response) => response.data);

      if (intentError) {
        console.log("[error]", intentError);
        setIsProcessing(false);
        return;
      }
      console.log(cardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: shippingDetails.nameOnCard,
              address: {
                city: shippingDetails.city,
                line1: shippingDetails.address1,
                line2: shippingDetails.address2,
                postal_code: shippingDetails.postalCode,
              },
            },
          },
          return_url:
            "https://www.votre-site.com/chemin-de-retour-apres-paiement", // URL où l'utilisateur sera redirigé après une authentification hors-site
        }
      );

      if (error) {
        console.log("[error]", error);
        setIsProcessing(false);
      } else {
        console.log("[PaymentIntent]", paymentIntent);
        setPaymentDetails({
          ...paymentDetails,
          ["paymentIntent"]: paymentIntent,
        });
        setIsProcessing(false);
        // console.log(paymentIntent)

        if (paymentIntent.status === "succeeded") {
          const paymentMethodId = paymentIntent.payment_method;
          // Le paiement a réussi, continuez le traitement de la commande
          try {
            // const paymentMethod = await stripe.paymentMethods.retrieve(
            //   paymentMethodId
            // );

            // setPaymentDetails({
            //   ...paymentDetails,
            //   ["paymentMethod"]: paymentMethod,
            // });

            const response = await axios.post(
              "https://www.editionspointcon.fr/api/Utilisateur/save-order",
              {
                paymentIntentId: paymentIntent.id,
                shippingDetails,
                cart: cart,
              }
            );
            if (response.status === 201) {
                showToast('success', 'Paiement effectuée avec succès!');
              // console.log("Réponse du serveur:", response.data);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              handleNext();
            }else{
                showToast('error', 'Paiement effectuée avec succès!');
            }
          } catch (backendError) {
            console.error(
              "Erreur lors de l'enregistrement de la commande:",
              backendError
            );
          }
        } else if (paymentIntent.status === "requires_action") {
          // L'utilisateur est redirigé vers la page d'authentification 3D Secure
          // Après l'authentification, l'utilisateur sera redirigé vers `return_url`
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          handleNext();
        } else {
          // Gérer les autres statuts éventuels
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Détails de paiement
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="nameOnCard"
              name="nameOnCard"
              label="Nom sur la carte"
              fullWidth
              autoComplete="cc-name"
              variant="standard"
              value={paymentDetails.nameOnCard || ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isProcessing}
          >
            {isProcessing ? "Traitement…" : "Suivant"}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
}
