
import { useContext } from 'react';
import { BookContext } from './BookContext';

export const useBooks = () => {
  const context = useContext(BookContext);
  if (!context) {
    throw new Error('useBooks doit être utilisé à l\'intérieur d\'un BookProvider');
  }
  return context;
};
