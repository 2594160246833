import React, { useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../Composant/NavBar";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,
} from "../../slices/cartSlice";
export default function ProductCards() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  return (
    <>
      <NavBar />
      <Box
        component="section"
        sx={{ bgcolor: "#eee", py: 5, minHeight: "100vh" }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={4}
              >
                <Typography variant="h3" gutterBottom>
                  Panier PointCon
                </Typography>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleClearCart}
                >
                  Vider le panier
                </Button>
              </Box>

              {cart.cartItems.map((cartItem, index) => (
                <Card key={index} sx={{ mb: 4 }}>
                  <CardContent>
                    <Grid container alignItems="center">
                      <Grid item xs={2}>
                        <CardMedia
                          component="img"
                          image={cartItem.Image}
                          alt={cartItem.Titre}
                          sx={{ borderRadius: "8px" }}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{ marginLeft: "20px" }}>
                        <Typography variant="h5">{cartItem.Titre}</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="body2" color="textSecondary">
                            Category: {cartItem.Categorie}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Note:{" "}
                            {cartItem.Note === "1"
                              ? "★☆☆☆☆"
                              : cartItem.Note === "2"
                              ? "★★☆☆☆"
                              : cartItem.Note === "3"
                              ? "★★★☆☆"
                              : cartItem.Note === "4"
                              ? "★★★★☆"
                              : cartItem.Note === "5"
                              ? "★★★★★"
                              : " /"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDecreaseCart(cartItem)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <span>{cartItem.cartQuantity}</span>
                        <IconButton onClick={() => handleAddToCart(cartItem)}>
                          <AddIcon />
                        </IconButton>
                      </Grid>

                      <Grid item xs={3}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <Typography variant="subtitle1" color="textSecondary">
                            Prix unitaire
                          </Typography>
                          <Typography variant="h6">
                            {cartItem.Prix}
                            {" €"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={1}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <Typography variant="subtitle1" color="textSecondary">
                            Total
                          </Typography>
                          <Typography variant="h6">
                            {parseFloat(
                              (cartItem.Prix * cartItem.cartQuantity).toFixed(2)
                            )}
                            {" €"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={1} sx={{ textAlign: "right" }}>
                        <IconButton
                          onClick={() => handleRemoveFromCart(cartItem)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}

              <Card sx={{ mt: 3 }}>
                <CardContent>
                  <Typography variant="h5">
                    {"Total a payé : "} {cart.cartTotalAmount}
                    {" €"}
                  </Typography>
                  {/* Here you can add more checkout details */}
                </CardContent>
              </Card>
              <Card sx={{ mt: 3 }}>
                <CardContent sx={{ padding: 3 }}>
                  {" "}
                  {/* Supprimer le padding par défaut de CardContent */}
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {window.location.href="/CheckOutPage"}}
                    fullWidth // Cette propriété fait que le bouton prend toute la largeur de son parent
                    //   sx={{ borderRadius: 0 }} // Optionnel: supprimer le borderRadius si vous voulez que le bouton s'aligne parfaitement avec les bords de la carte
                  >
                    Paiement
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
