import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const BookContext = createContext();

export const BookProvider = ({ children }) => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    
    const fetchBooks = async () => {
      try {
        const res = await axios.get(
          `https://www.editionspointcon.fr/api/Utilisateur/Histoire/GetHistoires?page=${1}&limit=${5}`
        );
        // console.log("code ",res.status," -")
        if (res.status === 200) {
          // console.log("use book"+res.data[0])
          // console.log(JSON.stringify(res.data[0]));

          const books = res.data.data.map((book, index) => {
            // console.log(book)
            // console.log(book.Images.length)
            return {
              id: book._id,
              Image:"https://www.editionspointcon.fr/"+book.Images[book.Images.length-2],//book.Images[0], // Prend la première image, ajustez selon les besoins
              Titre: book.Titre,
              Description: book.Chapitres.length > 0 ? book.Chapitres.length > 1 ? book.Chapitres[0].slice(1,4): book.Chapitres[0].slice(0,4): ["Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer"],
              Biographie: book.Resume.length > 0 ? [book.Resume.slice(0,3)] : ["Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer"],
              Auteur: book.Auteur,
              Categorie: book.Category,
              Prix:book.Prix,
              Note:book.Note,
              Format:book.Format,
              Disponibilite:book.Disponibilite,
            };
          });
          setBooks(books);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des livres:", error);
      }
    };

    fetchBooks();
  }, []);

  return (
    <BookContext.Provider value={{ books, setBooks }}>
      {children}
    </BookContext.Provider>
  );
};
