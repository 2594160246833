import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { styled } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import { useBooks } from "../useBook";
import Button from "../Composant/BoutonStyled";
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "300px",
  backgroundColor: "#FFFFFF",
  border: "solid 3px black",
  borderRadius: "30px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("630")]: { flexDirection: "column", height: "500px" },
  [theme.breakpoints.down("400")]: { height: "400px" },
  [theme.breakpoints.down("370")]: { height: "300px" },
}));

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "fit-content",
  flexDirection: "row",
  margin: "auto",
  [theme.breakpoints.down("630")]: { margin: "auto" },
}));

const ImageSection = styled("div")(({ theme, url }) => ({
  width: "50%",
  height: "100%",
  backgroundImage: `url(${url})`, 
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  borderRadius: "30px 0 0 30px",
  [theme.breakpoints.down("630")]: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    borderRadius: "30px 30px 0 0",
  },
  [theme.breakpoints.down("370")]: {
    minHeight: "120px",
  },
}));

const TextSection = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  padding: "20px",
  [theme.breakpoints.down("630")]: { width: "100%" },
}));

const Title = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 36,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("1370")]: { fontSize: 32 },
  [theme.breakpoints.down("1280")]: { fontSize: 28 },
  [theme.breakpoints.down("1160")]: { fontSize: 24 },
  [theme.breakpoints.down("1040")]: { fontSize: 20 },
  [theme.breakpoints.down("920")]: { fontSize: 16 },
  [theme.breakpoints.down("800")]: { fontSize: 32 },
  [theme.breakpoints.down("705")]: { fontSize: 28 },
  [theme.breakpoints.down("630")]: { fontSize: 30 },
  [theme.breakpoints.down("460")]: { fontSize: 26 },
  [theme.breakpoints.down("400")]: { fontSize: 20 },
  [theme.breakpoints.down("320")]: { fontSize: 16 },
  [theme.breakpoints.down("300")]: { fontSize: 14 },
}));

const Descri = styled(Box)(({ theme }) => ({
  fontFamily: "Irish Grover",
  color: "black",
  marginTop: "10px",
  marginBottom: "20px",
  textAlign: "justify",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "18px",
  [theme.breakpoints.down("1280")]: { fontSize: 14 },
  [theme.breakpoints.down("1160")]: { fontSize: 14 },
  [theme.breakpoints.down("1040")]: { fontSize: 12 },
  [theme.breakpoints.down("920")]: { fontSize: 12 },
  [theme.breakpoints.down("800")]: { fontSize: 18 },
  [theme.breakpoints.down("705")]: { fontSize: 16 },
  [theme.breakpoints.down("370")]: { fontSize: 12 },
  [theme.breakpoints.down("320")]: { fontSize: 10,lineHeight: "14px", },
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  height: "40px",
  width: "100%",
  margin: "auto",
}));
const StyledSlider = styled(Slider)({
  ".slick-slide": {
    padding: "0 0px",
  },
  ".slick-list": {
    margin: "0 0px",
  },
});

const StyledSlide = styled("div")(({ theme, isActive }) => ({
  textAlign: "center",
  transition: "transform 0.8s ease-in-out",
  transform: isActive ? "scale(1.0)" : "scale(0.7)",
  opacity: isActive ? 1 : 0.5,
  // Autres styles nécessaires
}));

const Carousel = ({ components }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const LivreCard = (props) => {
    return (
      <Container
        onClick={() => {
          if (windowWidth < 500) {
            window.location.href = "/" + props.Book.id;
          }
        }}
      >
        <ImageSection url={props.Book.Image}/>
        <TextSection>
          <TextContainer>
            <Title align={"center"}>
              {props.Book.Titre.slice(
                0,
                10
              )}
            </Title>
            <Title align={"center"} color={"#D58936"}>
              {" "}
              .Con
            </Title>
          </TextContainer>
          <div style={{ width: "85%", margin: "auto" }}>
            <Descri>{props.Book.Description.length>0 &&props.Book.Description[0].slice(0, 100) + "..."}</Descri>
          </div>
          {windowWidth > 500 ? (
            <ButtonContainer>
              <Button
                txt={"Lire !"}
                bordercolor={"black"}
                borderwidth={"2.0px"}
                textcolor={"white"}
                func={(e) => {
                  window.location.href = "/" + props.Book.id;
                }}
              />
            </ButtonContainer>
          ) : (
            <></>
          )}
        </TextSection>
      </Container>
    );
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [activeSlide, setActiveSlide] = useState(0);
  const { books } = useBooks();
  // console.log(books)
  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    beforeChange: (_, next) => setActiveSlide(next),
    draggable: true, // Active le glissement avec la souris
    swipeToSlide: true, // Permet de glisser pour passer à la diapositive suivantes
    speed: 1300, // Augmenter pour ralentir le changement de diapositive
    cssEase: "ease-in-out", // Fonction de timing pour une transition plus fluide
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledSlider {...settings}>
      {books && books.length>0  && books.map((Book, index) => (
        <StyledSlide key={index} isActive={index === activeSlide}>
          <LivreCard Book={Book} />
        </StyledSlide>
      ))}
    </StyledSlider>
  );
};

export default Carousel;
