import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { showToast } from "../component/Composant/toastNotifications";
import axios from "axios";
const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      console.log("state " + state.id);
      console.log("action " + action.payload.id);
      const existingIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex >= 0) {
        state.cartItems[existingIndex].cartQuantity += 1;
        showToast("info", "Increased product quantity");
        // toast.info("Increased product quantity", {
        //   position: "bottom-left",
        // });
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProductItem);
        showToast("success", "Product added to cart");
        // toast.success("Product added to cart", {
        //   position: "bottom-left",
        // });
      }
      saveCartToServer(state.cartItems);
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndex >= 0) {
        if (state.cartItems[itemIndex].cartQuantity > 1) {
          state.cartItems[itemIndex].cartQuantity -= 1;
          showToast("info", "Decreased product quantity");
          // toast.info("Decreased product quantity", {
          //   position: "bottom-left",
          // });
        } else if (state.cartItems[itemIndex].cartQuantity === 1) {
          state.cartItems.splice(itemIndex, 1);
          showToast("error", "Product removed from cart");
          // toast.error("Product removed from cart", {
          //   position: "bottom-left",
          // });
        }
      }

      saveCartToServer(state.cartItems);
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
      showToast("error","Product removed from cart");
      // toast.error("Product removed from cart", {
      //   position: "bottom-left",
      // });
      saveCartToServer(state.cartItems);
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    getTotals(state, action) {
      let total = 0;
      let quantity = 0;

      state.cartItems.forEach((item) => {
        total += item.Prix * item.cartQuantity;
        quantity += item.cartQuantity;
      });
      total = parseFloat(total.toFixed(2));
      state.cartTotalAmount = total;
      state.cartTotalQuantity = quantity;
    },
    clearCart(state, action) {
      state.cartItems = [];
      showToast("error","Cart cleared");
      // toast.error("Cart cleared", { position: "bottom-left" });
      saveCartToServer(state.cartItems);
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    loadCartItems(state, action) {
      state.cartItems = action.payload;

      let totalQuantity = 0;
      let totalAmount = 0;

      action.payload.forEach((item) => {
        totalQuantity += item.cartQuantity;
        totalAmount += item.cartQuantity * item.Prix;
      });

      state.cartTotalQuantity = totalQuantity;
      state.cartTotalAmount = totalAmount;
    },
  },
});
const saveCartToServer = async (cartItems) => {
  console.log("le cart : ");
  // console.log(cartItems[0].id)
  try {
    const response = await axios.post(
      "https://www.editionspointcon.fr/api/Utilisateur/cart",
      {
        cartItems,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      console.log("Panier sauvegardé avec succès");
    } else {
      console.error("Erreur lors de la sauvegarde du panier", response);
    }
  } catch (error) {
    console.error("Erreur lors de la communication avec le serveur", error);
  }
};
export const {
  addToCart,
  decreaseCart,
  removeFromCart,
  getTotals,
  clearCart,
  loadCartItems,
} = cartSlice.actions;

export default cartSlice.reducer;
