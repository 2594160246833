import React from "react"; //{ useState, useEffect }
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import NavBar from "../Composant/NavBar";
// import { useBooks } from "../useBook";
// const axios = require("axios");

const Content = styled("div")(({ theme }) => ({
  //backgroundColor: "#252525",
  width: "100%",
  backgroundColor: "#D8D8D8",
  minHeight: "130vh",
  paddingBottom: "80px",
}));

const WhiteContent = styled("div")(({ theme }) => ({
  width: "70%",
  minHeight: "100vh",
  margin: "auto",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("740")]: { width: "100%" },
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("740")]: { flexDirection: "column" },
}));

const TextContentContainer = styled("div")(({ theme }) => ({
  width: "55%",
  margin: "auto",
  [theme.breakpoints.down("740")]: { width: "85%" },
}));

const CardBloc = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: "400px",
  height: "100%",
  border: "solid 2px #1C1E2A",
  borderRadius: "40px",
  margin: "auto",
  marginTop: "80px",
  backgroundColor: "white",
  padding: "40px",
  paddingTop: "50px",
  justifyContent: "center",
  alignItems: "center",
}));
const TitreE = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 48,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("880")]: { fontSize: 36 },
  [theme.breakpoints.down("400")]: { fontSize: 28 },
}));

const Descri = styled(Box)(({ theme }) => ({
  fontFamily: "Irish Grover",
  color: "black",
  marginTop: "40px",
  marginBottom: "20px",
  textAlign: "justify",
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "25px",
  [theme.breakpoints.down("880")]: { fontSize: 15 },
  [theme.breakpoints.down("740")]: { fontSize: 20 },
  [theme.breakpoints.down("590")]: { fontSize: 16 },
  [theme.breakpoints.down("400")]: { marginTop: "20px", fontSize: 12 },
  [theme.breakpoints.down("350")]: {
    marginTop: "10px",
    fontSize: 10,
    lineHeight: "15px",
  },
}));

export default function Page() {
  // const { books } = useBooks();
  //******************************** */
  // const useWindowSize = () => {
  //   const [size, setSize] = useState(window.innerWidth);
  //   useEffect(() => {
  //     const handleResize = () => {
  //       setSize(window.innerWidth);
  //     };
  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);

  //   return size;
  // };

  const TitlePart = () => {
    return (
      <div
        style={{
          display: "flex",
          width: "fit-content",
          flexDirection: "row",
          margin: "auto",
        }}
      >
        <TitreE align={"center"}>Editions</TitreE>
        <TitreE align={"center"} color={"#D58936"}>
          {" "}
          .Con
        </TitreE>
      </div>
    );
  };
  const Card = () => {
    return (
      <CardBloc>
        <TitlePart />
        <ContentContainer>
          <TextContentContainer>
            <Descri>
              Hector Bebête est un personnage de fiction unique, conçu
              spécifiquement pour apporter un aspect humoristique et léger. Il
              est l'incarnation de l'esprit joyeux et sert de mascotte pour les
              éditions où il apparaît. Avec ses traits exagérés et son attitude
              espiègle, Hector est immédiatement reconnaissable et apporte une
              touche de fantaisie partout où il est présenté. Physiquement,
              Hector est souvent représenté avec des proportions caricaturales,
              comme un grand sourire qui occupe la majeure partie de son visage,
              des yeux pétillants et expressifs, et peut-être même des vêtements
              extravagants qui reflètent son caractère ludique. Sa démarche est
              dynamique et sa présence est souvent accompagnée d'éléments
              comiques, comme des situations absurdes ou des accessoires
              humoristiques. Dans ses aventures ou apparitions, Hector a pour
              but de faire sourire et rire, souvent en se retrouvant dans des
              situations rocambolesques ou en faisant des remarques
              spirituelles. Il peut également avoir un côté légèrement maladroit
              ou naïf, ce qui le rend encore plus attachant et divertissant. Ce
              personnage est un exemple parfait de la manière dont la fiction
              peut être utilisée pour apporter de la joie et de l'humour dans le
              monde de l'édition, en offrant un échappatoire amusant et une
              source de divertissement légère pour les lecteurs de tous âges.
            </Descri>
          </TextContentContainer>
          <img
            src="/RightImg.png"
            alt="description "
            style={{
              width: "30%",
              height: "auto",
              margin: "auto",
            }}
          />
        </ContentContainer>
      </CardBloc>
    );
  };
  //************************************************* */
  return (
    <div>
      <Content>
        <NavBar />
        <WhiteContent>
          <Card />
        </WhiteContent>
      </Content>
    </div>
  );
  //******************************************** */
}
