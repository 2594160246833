import React, { createContext, useState } from "react";

export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  // État pour les détails de livraison
  const [shippingDetails, setShippingDetails] = useState({
    city: "",
    postalCode: "",
    country: "France",
  });

  // État pour les détails de paiement
  const [paymentDetails, setPaymentDetails] = useState({
    nameOnCard: "",
  });

  const [paymentMethodId, setPaymentMethodId] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  return (
    <CheckoutContext.Provider
      value={{
        shippingDetails,
        setShippingDetails,
        paymentDetails,
        setPaymentDetails,
        paymentMethodId,
        setPaymentMethodId,
        activeStep,
        setActiveStep,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
