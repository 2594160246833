import React from "react";
import Page from "./page";


export default function FirstPage(props) {
  //**************************************************/
  return (
    <div>
      <main
        style={{
          backgroundColor: "#D8D8D8",
          //backgroundImage: "url('/Fond.png')",
          backgroundSize: "cover", 
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Page />
      </main>
    </div>
  );

  //******************************************** */
}
