import React, { useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { CheckoutContext } from "../../CheckOutContext";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../../slices/cartSlice";
import { showToast } from "../../Composant/toastNotifications";
const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];

export default function Review({ handleNext }) {
  const { shippingDetails, paymentDetails, setPaymentDetails, setActiveStep } =
    useContext(CheckoutContext);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  console.log("******");
  console.log(cart);
  console.log(shippingDetails);
  //   const payments = [
  //     { name: "Type", detail: paymentDetails.paymentMethod.card.brand },
  //     { name: "Nom", detail: paymentDetails.nameOnCard },
  //     {
  //       name: "N° Carte",
  //       detail: "xxxx-xxxx-xxxx-" + paymentDetails.paymentMethod.card.last4,
  //     },
  //     {
  //       name: "Exp",
  //       detail:
  //         paymentDetails.paymentMethod.card.exp_month +
  //         "/" +
  //         paymentDetails.paymentMethod.card.exp_year,
  //     },
  //   ];
  const products = cart.cartItems.map((item) => ({
    name: item.Titre,
    desc:
      item.Note === "1"
        ? "★☆☆☆☆"
        : item.Note === "2"
        ? "★★☆☆☆"
        : item.Note === "3"
        ? "★★★☆☆"
        : item.Note === "4"
        ? "★★★★☆"
        : item.Note === "5"
        ? "★★★★★"
        : " /",
    price: `${parseFloat((item.Prix * item.cartQuantity).toFixed(2))} €`,
  }));

  products.push({ name: "Shipping", desc: "", price: "Free" });

  console.log(products);
  const handelFinal = () => {
    dispatch(clearCart());
    handleNext();
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {parseFloat(cart.cartTotalAmount.toFixed(2))}
            {" €"}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Livraison
          </Typography>
          <Typography gutterBottom>
            {shippingDetails.lastName + " " + shippingDetails.firstName}
          </Typography>
          <Typography gutterBottom>{shippingDetails.address1}</Typography>
          <Typography gutterBottom>{shippingDetails.address2}</Typography>
          <Typography gutterBottom>
            {shippingDetails.city + " " + shippingDetails.postalCode}
          </Typography>
        </Grid>
        {/* <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={4}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid> */}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2rem",
        }}
      >
        <Button variant="contained" color="primary" onClick={handelFinal}>
          Finaliser
        </Button>
      </div>
    </React.Fragment>
  );
}
