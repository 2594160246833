import React from "react";
import { styled } from "@mui/material/styles";
import Button from "../Composant/BoutonStyled";
import Box from "@mui/material/Box";
import NavBar from "../Composant/NavBar";
import TextField from "@mui/material/TextField";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import axios from "axios";
const Content = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  paddingBottom: "50px",
}));
const TextPart = styled("div")(({ theme }) => ({
  width: "fit-content",
  margin: "auto",
  height: "120px",
  textAlign: "center",
  paddingTop: "50px",
}));
const FormContent = styled("div")(({ theme }) => ({
  width: "90%",
  margin: "auto",
  marginTop: "30px",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
const PartContainer = styled("div")(({ theme }) => ({
  width: "90%",
  margin: "auto",
  height: "fit-content",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingTop: 20,
}));
const PartContainer2 = styled("div")(({ theme }) => ({
  width: "70%",
  margin: "auto",
  height: "fit-content",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingTop: 20,
}));
const InputContainer = styled("div")(({ theme }) => ({
  width: "25%",
  height: "fit-content",
  minHeight: "80px",
  minWidth: "250px",
  margin: "auto",
}));
const InputContainerMulti = styled("div")(({ theme }) => ({
  width: "90%",
  height: "fit-content",
  minHeight: "80px",
  margin: "auto",
}));
const DynamicContainer = styled("div")(({ theme }) => ({
  width: "90%",
  height: "fit-content",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingTop: 20,
}));
const DynamicPartContainer = styled("div")(({ theme }) => ({
  width: "70%",
  margin: "auto",
  height: "fit-content",
}));
const Title = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: fontSize || 40,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("880")]: { fontSize: 36 },
  [theme.breakpoints.down("400")]: { fontSize: 28 },
}));
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-outlined": {
    transform: "translate(-50%, -50%) scale(1)", // Garder le label centré
    left: "50%",
    top: "50%",
    pointerEvents: "none", // Le label ne doit pas intercepter les clics
    backgroundColor: "transparent", // Aucun fond pour ne pas cacher le champ de saisie
  },
  "& .MuiInputLabel-shrink": {
    display: "none", // Cacher le label quand le TextField est focus
  },
  "& .MuiOutlinedInput-input": {
    textAlign: "center", // Centrer le texte saisi
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 20,
    "& fieldset": {
      border: "solid 3px black",
    },
    "&:hover fieldset": {
      border: "solid 3px black",
    },
    "&.Mui-focused fieldset": {
      border: "solid 3px black",
    },
  },
  "& input": {
    height: "25px",
    color: "black", // couleur du texte saisi
    fontFamily: "Irish Grover",
    fontSize: 20,
    fontWeight: 700,
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontFamily: "Irish Grover", // Appliquer la police au placeholder
    // Autres styles spécifiques au placeholder, si nécessaire
    opacity: 1,
  },
  width: "100%",
  margin: "auto",
  marginTop: "10px",
  [theme.breakpoints.down("760")]: {
    "& input": {
      fontSize: 16,
    },
  },
}));
const CustomFileInput = styled("div")(({ theme }) => ({
  input: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  label: {
    fontFamily: "Irish Grover",
    color: "black",
    backgroundColor: "white",
    display: "inline-block",
    padding: theme.spacing(1, 2),
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
    marginTop: theme.spacing(1),
    borderRadius: 20,
    border: "solid 3px black",
    cursor: "pointer",
    "&:hover": {
      border: "solid 3px black",
    },
  },
  [theme.breakpoints.down("760")]: {
    label: {
      fontSize: 16,
    },
  },
}));
const CustomOutlinedInput = styled(OutlinedInput)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "3px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "3px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "3px",
  },
  borderRadius: 20,
  backgroundColor: "white",
  fontFamily: "Irish Grover",
});
const CustomFormControl = styled(FormControl)({
  width: "40%",
  marginTop: "10px",
  "& .MuiInputLabel-outlined": {
    fontFamily: "Irish Grover",
  },
  "& .MuiInputLabel-shrink": {
    backgroundColor: "white",
    padding: "0 4px",
  },
});
const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  fontFamily: "Irish Grover",
  fontSize: 20,
  fontWeight: 700,
  color: "black",
  [theme.breakpoints.down("760")]: { fontSize: 16 },
}));
const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    textAlign: "left",
    fontFamily: "Irish Grover",
    fontWeight: 700,
  },
  "& .MuiSelect-icon": {
    color: "black",
  },
});
const CustomMenuItem = styled(MenuItem)({
  fontFamily: "Irish Grover",
  fontWeight: 700,
});
// const histoire = {
//   Titre: "",
//   Resume: [""],
//   Auteur: "",
//   Chapitre: [[""]],
//   Category: "",
// };
export default function Register() {
  const [Titre, setTitre] = React.useState("");
  const [Auteur, setAuteur] = React.useState("");
  const [Prix, setPrix] = React.useState("");
  const [Format, setFormat] = React.useState("");
  const [Categorie, setCategorie] = React.useState("");
  const [fields, setFields] = React.useState([""]);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [chapters, setChapters] = React.useState([[""]]); // Initialisé avec un chapitre contenant un champ vide

  const sendFormData = () => {
    const formData = new FormData();
    formData.append("Titre", Titre);
    formData.append("Resume", JSON.stringify(fields));
    formData.append("Auteur", Auteur);
    formData.append("Chapitre", JSON.stringify(chapters));
    formData.append("Category", Categorie);
    formData.append("Prix", Prix);
    // console.log("---------- "+Format)
    formData.append("Format", Format);
    formData.append("Chapitre_titles", JSON.stringify([""]));

    if (selectedFiles && selectedFiles.length) {
      selectedFiles.forEach((file) => {
        formData.append("images", file);
      });
      console.log(`Uploading ${selectedFiles.length} images`);
    } else {
      console.log("No images to upload");
    }

    const url = "https://www.editionspointcon.fr/api/Utilisateur/Histoire/CreateHistoire";
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data', // Doit être 'multipart/form-data' pour le FormData
      },
      withCredentials: true
    };

    axios.post(url, formData, config)
      .then((response) => console.log("Response:", response.data))
      .catch((error) => {
        console.error("Error:", error);
        // Ajouter ici la logique pour gérer l'erreur (comme informer l'utilisateur)
      });
};
// const SnedTest = () => {
//   const formData = new FormData();
//   formData.append("Titre", "Titre");
  
//   const url = "https://37.187.39.75:8081/Utilisateur/Histoire/Histoire";
//   const config = {
//     headers: {
//       'Content-Type': 'multipart/form-data', 
//     },
//     withCredentials: true
//   };

//   axios.post(url, formData, config)
//     .then((response) => console.log("Response:", response.data))
//     .catch((error) => {
//       console.error("Error:", error);
     
//     });
// };


  const addField = () => {
    setFields([...fields, { id: Date.now(), value: "" }]);
  };

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleFieldChange = (id, event) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };

  const addFieldToChapter = (chapterIndex) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex].push(""); // Ajoute un nouveau champ vide au chapitre spécifié
    setChapters(newChapters);
  };

  const addChapter = () => {
    setChapters([...chapters, [""]]); // Ajoute un nouveau chapitre avec un champ vide
  };

  const handleFieldChangeChapter = (chapterIndex, fieldIndex, event) => {
    const newChapters = [...chapters];
    newChapters[chapterIndex][fieldIndex] = event.target.value;
    setChapters(newChapters);
  };

  //************************************************* */
  return (
    <div>
      <Content>
        <NavBar />
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
            marginTop: "20px",
          }}
        >
          <Button
            width={200}
            height={50}
            txt={"Valider"}
            Click={0}
            func={() =>sendFormData()}
            borderradius={"25px"}
          />
        </div>

        <TextPart>
          <Title color={"#D58936"}>Histoire form</Title>
        </TextPart>
        <FormContent>
          <div style={{ width: "fit-content", margin: "auto" }}>
            <CustomFileInput>
              <input
                type="file"
                multiple
                id="file"
                onChange={handleFileChange}
              />
              <label htmlFor="file">Choisissez des fichiers</label>
            </CustomFileInput>
          </div>
          <PartContainer>
            <InputContainer>
              <CustomTextField
                placeholder="Titre de l'histoire"
                onChange={(e) => {
                  setTitre(e.target.value);
                }}
                value={Titre}
              />
            </InputContainer>

            <InputContainer>
              <CustomTextField
                placeholder="Auteur"
                onChange={(e) => {
                  setAuteur(e.target.value);
                }}
                value={Auteur}
              />
            </InputContainer>

            <InputContainer>
              <CustomTextField
                placeholder="Prix"
                onChange={(e) => {
                  setPrix(e.target.value);
                }}
                value={Prix}
              />
            </InputContainer>
          </PartContainer>
          <PartContainer2>
            <CustomFormControl
              variant="outlined"
              style={{ marginBottom: "10px" }}
            >
              <InputLabelStyled>Format</InputLabelStyled>
              <CustomSelect
                value={Format}
                onChange={(e) => setFormat(e.target.value)}
                input={<CustomOutlinedInput />}
              >
                <CustomMenuItem value="">****</CustomMenuItem>
                <CustomMenuItem value="Mini">Mini</CustomMenuItem>
                <CustomMenuItem value="Livre">Livre</CustomMenuItem>
                {/* Ajouter d'autres genres ici */}
              </CustomSelect>
            </CustomFormControl>

            <CustomFormControl
              variant="outlined"
              style={{ marginBottom: "10px" }}
            >
              <InputLabelStyled>Categorie</InputLabelStyled>
              <CustomSelect
                value={Categorie}
                onChange={(e) => setCategorie(e.target.value)}
                input={<CustomOutlinedInput />}
              >
                <CustomMenuItem value="Aucun">Aucun</CustomMenuItem>
                <CustomMenuItem value="Fiction">Fiction</CustomMenuItem>
                <CustomMenuItem value="Science">Science</CustomMenuItem>
                {/* Ajouter d'autres genres ici */}
              </CustomSelect>
            </CustomFormControl>
          </PartContainer2>
          <DynamicContainer>
            <DynamicPartContainer>
              <div>
                {chapters.map((chapter, chapterIndex) => (
                  <div>
                    <div
                      key={chapterIndex}
                      style={{ minHeight: "260px", height: "fit-content" }}
                    >
                      <TextPart>
                        <Title>Chapitre {chapterIndex + 1}</Title>
                      </TextPart>
                      {chapter.map((field, fieldIndex) => (
                        <InputContainerMulti key={fieldIndex}>
                          <CustomTextField
                            key={`${chapterIndex}-${fieldIndex}`}
                            type="text"
                            placeholder="chapitre"
                            multiline
                            value={field}
                            onChange={(e) =>
                              handleFieldChangeChapter(
                                chapterIndex,
                                fieldIndex,
                                e
                              )
                            }
                          />
                        </InputContainerMulti>
                      ))}
                    </div>

                    <div
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        margin: "auto",
                      }}
                    >
                      <Button
                        width={200}
                        height={50}
                        txt={"Ajouter un champ"}
                        Click={0}
                        func={() => addFieldToChapter(chapterIndex)}
                        borderradius={"25px"}
                      />
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    width: "fit-content",
                    height: "150px",
                    margin: "auto",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    width={200}
                    height={50}
                    txt={"Ajouter un chapitre"}
                    Click={0}
                    func={addChapter}
                    borderradius={"25px"}
                  />
                </div>
              </div>
            </DynamicPartContainer>
            <DynamicPartContainer>
              <div>
                {fields.map((field, index) => (
                  <div
                    key={`div-${index}`}
                    style={{ minHeight: "280px", height: "fit-content" }}
                  >
                    <TextPart key={`TextPart-${index}`}>
                      <Title key={`Titre-${index}`}>Paragraphe</Title>
                    </TextPart>
                    <InputContainerMulti key={`InputContainer-${index}`}>
                      <CustomTextField
                        key={`CustomTextField-${index}`}
                        id={"Paragraphe"}
                        name={"Paragraphe"}
                        placeholder="Paragraphe"
                        multiline
                        value={field.value}
                        onChange={(e) => handleFieldChange(field.id, e)}
                      />
                    </InputContainerMulti>
                  </div>
                ))}
                <div
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    margin: "auto",
                  }}
                >
                  <Button
                    width={200}
                    height={50}
                    txt={"Ajouter un paragraphe"}
                    Click={0}
                    func={addField}
                    borderradius={"25px"}
                  />
                </div>
              </div>
            </DynamicPartContainer>
          </DynamicContainer>
        </FormContent>
      </Content>
    </div>
  );
  //******************************************** */
}
