import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "../Composant/BoutonStyled";
import Box from "@mui/material/Box";
import NavBar from "../Composant/NavBar";
// import { useBooks } from "../useBook";
// const axios = require("axios");

const Content = styled("div")(({ theme }) => ({
  //backgroundColor: "#252525",
  width: "100%",
  height: "fit-content",
  minHeight: "100vh",
  maxHeight: "120vh",
  [theme.breakpoints.down("1444")]: { gridTemplateColumns: "repeat(3, 1fr)" },
  [theme.breakpoints.down("1135")]: { gridTemplateColumns: "repeat(2, 1fr)" },
  [theme.breakpoints.down("850")]: { gridTemplateColumns: "repeat(1, 1fr)" },
}));

const ContentBloc = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "90vh",
  [theme.breakpoints.down("740")]: {
    flexDirection: "column",
    height: "fit-content",
  },
}));

const LeftBloc = styled("div")(({ theme }) => ({
  width: "40%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("740")]: { width: "100%" },
}));

const RightBloc = styled("div")(({ theme }) => ({
  width: "60%",
  height: "100%",
  [theme.breakpoints.down("740")]: {
    width: "fit-content",
    height: "fit-content",
  },
}));

const CardBloc = styled("div")(({ theme }) => ({
  width: "70%",
  minWidth: "400px",
  height: "340px",
  border: "solid 2px #1C1E2A",
  borderRadius: "40px",
  marginRight: "0",
  marginLeft: "auto",
  marginTop: "80px",
  backgroundColor: "white",
  padding: "40px",
  paddingTop: "50px",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("1030")]: { minWidth: "340px", height: "360px" },
  [theme.breakpoints.down("880")]: { minWidth: "280px", height: "380px" },
  [theme.breakpoints.down("740")]: {
    width: "80%",
    height: "360px",
    margin: "auto",
    marginTop: "80px",
  },
  [theme.breakpoints.down("400")]: { minWidth: "200px", height: "320px" },
  [theme.breakpoints.down("350")]: { minWidth: "200px", height: "280px" },
}));
const TitreE = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 48,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("880")]: { fontSize: 36 },
  [theme.breakpoints.down("400")]: { fontSize: 28 },
}));

const Descri = styled(Box)(({ theme }) => ({
  fontFamily: "Irish Grover",
  color: "black",
  marginTop: "40px",
  marginBottom: "20px",
  textAlign: "justify",
  fontSize: 18,
  fontWeight: 500,
  lineHeight: "25px",
  [theme.breakpoints.down("880")]: { fontSize: 15 },
  [theme.breakpoints.down("740")]: { fontSize: 20 },
  [theme.breakpoints.down("590")]: { fontSize: 16 },
  [theme.breakpoints.down("400")]: { marginTop: "20px", fontSize: 12 },
  [theme.breakpoints.down("350")]: {
    marginTop: "10px",
    fontSize: 10,
    lineHeight: "15px",
  },
}));

export default function Page() {
  // const { books } = useBooks();
  //******************************** */
  const useWindowSize = () => {
    const [size, setSize] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => {
        setSize(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return size;
  };

  const TitlePart = () => {
    return (
      <div
        style={{
          display: "flex",
          width: "fit-content",
          flexDirection: "row",
          margin: "auto",
        }}
      >
        <TitreE align={"center"}>Editions</TitreE>
        <TitreE align={"center"} color={"#D58936"}>
          {" "}
          .Con
        </TitreE>
      </div>
    );
  };
  const Card = () => {
    return (
      <CardBloc>
        <TitlePart />
        <Descri>
          Bienvenue chez Éditions Point Con, là où chaque page est une
          invitation à sourire et à s'émerveiller. Plongez dans nos ouvrages,
          véritables trésors d'histoires drôles.
        </Descri>
        <div style={{ width: "150px", margin: "auto" }}>
          <Button
            txt={"C'est parti !"}
            func={(e) => {
              window.location.href = "/Home";
            }}
          />
        </div>
      </CardBloc>
    );
  };
  //************************************************* */
  return (
    <div>
      <Content>
        <NavBar />
        <ContentBloc>
          <LeftBloc>
            <Card />
          </LeftBloc>
          <RightBloc>
            <img
              src="/Group 48.png"
              alt="description "
              style={{
                width: useWindowSize() / 2,
                height: "auto",
                maxWidth: "800px",
                minWidth: "320px",
                position: useWindowSize() > 740 ? "fixed" : "static",
                right: useWindowSize() > 740 ? 0 : "",
                top: useWindowSize() > 740 ? "50%" : "",
                transform: useWindowSize() > 740 ? "translateY(-50%)" : "none",
                bottom: useWindowSize() > 740 ? "0" : "",
                margin: useWindowSize() <= 740 ? "20px auto" : "",
              }}
            />
          </RightBloc>
        </ContentBloc>
      </Content>
    </div>
  );
  //******************************************** */
}
