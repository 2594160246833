import React, { useState } from "react";
import { styled, useTheme } from "@mui/system";
import { IconButton, Menu, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavBar from "./NavBar";
import Box from "@mui/material/Box";
import Button from "../Composant/BoutonStyled";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import ActionButtons from "./ActionButtons";
import { jsPDF } from "jspdf";
import axios from "axios";
const Body = styled("div")(({ theme }) => ({
  backgroundColor: "#D8D8D8",
  minHeight: "120vh",
  height: "fit-content",
  zIndex: -1,
}));
const Content = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  width: "75%",
  height: "fit-content",
  [theme.breakpoints.down("910")]: { width: "95%" },
}));
const TopImgDiv = styled("div")(({ theme }) => ({
  width: "90%",
  height: "200px",
  marginLeft: "auto",
  marginRight: "100px",
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("910")]: {
    height: "460px",
    width: "100%",
    flexDirection: "column-reverse",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("665")]: {
    height: "390px",
  },
  [theme.breakpoints.down("401")]: {
    height: "310px",
    margin: "auto",
    width: "fit-content",
  },
}));
const TopImgContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "350px",
  height: "fit-content",
  border: "solid 3px black",
  borderRadius: "30px",
  backgroundColor: "#FFFFFF",
  [theme.breakpoints.down("910")]: {
    position: "",
    margin: "auto",
    marginTop: "10px",
  },
  [theme.breakpoints.down("665")]: {
    width: "300px",
    height: "310px",
  },
  [theme.breakpoints.down("401")]: {
    width: "260px",
    height: "260px",
    marginTop: "45px",
  },
}));
const TitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "fit-content",
  flexDirection: "row",
  marginLeft: "45%",
  marginRight: "auto",
  marginTop: "80px",
  height: "100px",
  [theme.breakpoints.down("1175")]: {
    marginLeft: "auto",
    marginRight: "0",
  },
  [theme.breakpoints.down("910")]: {
    height: "fit-content",
    margin: "auto",
    marginTop: "0px",
  },
}));
const WhiteContainer = styled("div")(({ theme }) => ({
  width: "90%",
  height: "fit-content",
  minHeight: "660px",
  marginLeft: "auto",
  marginRight: "0",
  marginBottom: "20px",
  border: "solid 3px black",
  borderRadius: "30px",
  backgroundColor: "#FFFFFF",
  [theme.breakpoints.down("910")]: {
    width: "100%",
  },
}));
const ActionTopDiv = styled("div")(({ theme }) => ({
  height: "150px",
  width: "90%",
  marginRight: "10px",
  marginLeft: "auto",
  [theme.breakpoints.down("910")]: {
    width: "100%",
    height: "80px",
  },
}));
const TopActionPart = styled("div")(({ theme }) => ({
  width: "75%",
  height: "100%",
  marginRight: "0",
  marginLeft: "auto",
  borderRadius: "0 30px 0 0",
  [theme.breakpoints.down("1155")]: {
    width: "65%",
  },
  [theme.breakpoints.down("1030")]: {
    width: "55%",
  },
  [theme.breakpoints.down("910")]: {
    width: "100%",
  },
}));
const TopActionContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "80%",
  minHeight: "60px",
  maxHeight: "80px",
  borderRadius: "0 30px 0 0",
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("520")]: {
    width: "90%",
    margin: "auto",
    borderRadius: "30px 30px 0 0",
  },
}));
const ContentWriting = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  borderRadius: "0 0 30px 30px",
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("700")]: {
    flexDirection: "column-reverse",
  },
}));
const LeftContent = styled("div")(({ theme }) => ({
  width: "30%",
  height: "fit-content",
  marginLeft: "10px",
  marginRight: "auto",
  borderRadius: "0 0 0 30px",
  [theme.breakpoints.down("700")]: {
    width: "80%",
    margin: "auto",
  },
  [theme.breakpoints.down("401")]: {
    width: "90%",
    margin: "auto",
  },
}));
const RightContent = styled("div")(({ theme }) => ({
  width: "65%",
  height: "100%",
  // backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "10px",
  borderRadius: "0 0 30px 0",
  [theme.breakpoints.down("700")]: {
    width: "80%",
    margin: "auto",
  },
  [theme.breakpoints.down("401")]: {
    width: "90%",
    margin: "auto",
  },
}));

const Devider = styled("div")(({ theme }) => ({
  width: "85%",
  height: "2px",
  margin: "auto",
  backgroundColor: "#1C1E2A",
  [theme.breakpoints.down("910")]: {
    width: "90%",
  },
}));

const PricingContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "40px",
  paddingTop: "20px",
  [theme.breakpoints.down("600")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingLeft: "20px",
    paddingTop: "0px",
  },
}));

const TitreE = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: fontSize || 48,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("1175")]: { fontSize: fontSize || 40 },
  [theme.breakpoints.down("1005")]: { fontSize: fontSize || 32 },
}));
const Pricing = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: fontSize || 20,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("1200")]: { fontSize: 18 },
  [theme.breakpoints.down("1256")]: { fontSize: 16 },
}));
const Disponibility = styled(Box)(
  ({ theme, fontSize, color, align, lineH }) => ({
    fontFamily: "Irish Grover",
    color: color || "black",
    fontSize: fontSize || 20,
    fontWeight: 700,
    lineHeight: lineH || "40px",
    textAlign: align || "left",
    marginRight: "auto",
    marginLeft: "40px",
    [theme.breakpoints.down("1200")]: { fontSize: 18 },
    [theme.breakpoints.down("1256")]: { fontSize: 16 },
    [theme.breakpoints.down("600")]: { marginRight: "auto", marginLeft: "0" },
  })
);
const Descri = styled(Box)(({ theme, fontSize }) => ({
  fontFamily: "Irish Grover",
  color: "black",
  marginTop: "10px",
  marginBottom: "20px",
  textAlign: "justify",
  fontSize: fontSize || 14,
  fontWeight: 500,
  lineHeight: "25px",
  [theme.breakpoints.down("401")]: { fontSize: 12 },
}));
const DescriptionContainer = styled(Box)(({ theme, fontSize }) => ({
  width: "90%",
  margin: "auto",
  height: "fit-content",
  marginTop: "20px",
  justifyContent: "left",
  alignItems: "left",
  [theme.breakpoints.down("401")]: { width: "100%" },
}));
const StyledImage = styled("img")(({ theme, fontSize }) => ({
  width: "100%",
  borderRadius: "30px",
  height: "auto",
}));
const RightHectorContainer = styled(Box)(({ theme, fontSize }) => ({
  width: "20%",
  height: "500px",
  minWidth: "100px",
  minHeight: "100px",
  maxHeight: "200px",
  position: "absolute",
  top: 65,
  right: 10,
  zIndex: -0,
  backgroundImage: "url('/RightImg.png')",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top right",
  [theme.breakpoints.down("500")]: {
    height: "100px",
    minHeight: "100px",
  },
}));

const generatePDF = (Book) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;
  const maxLineWidth = pageWidth - 2 * margin;
  doc.setFont("Irish Grover", "normal");

  const addCenteredText = (text, y) => {
    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset = (pageWidth - textWidth) / 2;
    doc.text(text, textOffset, y);
  };

  const addMultilineText = (text, x, y) => {
    const lines = doc.splitTextToSize(text, maxLineWidth);
    lines.forEach((line) => {
      if (y > doc.internal.pageSize.height - 10) {
        doc.addPage();
        y = 20;
      }
      doc.text(line, x, y);
      y += 10;
    });
    return y;
  };
  doc.setFont("Irish Grover", "bold");
  doc.setFontSize(20);
  addCenteredText(Book.Titre, 30);
  doc.setFont("Irish Grover", "normal");
  doc.setFontSize(12);
  addCenteredText("Auteur: " + Book.Auteur, 40);
  addCenteredText("Catégorie: " + Book.Categorie, 50);

  let currentY = 60;
  const imgWidth = 120;
  const imgHeight = 120;
  const imgOffset = (pageWidth - imgWidth) / 2; // Calcul du décalage pour centrer l'image
  if (currentY + imgWidth > doc.internal.pageSize.height) {
    doc.addPage();
    currentY = 20; // Réinitialiser la position Y pour la nouvelle page
  }
  // Remplacer par le code d'ajout d'image ici
  // Ajout de l'image
  function loadImage(url, callback) {
    axios({
      method: "get",
      url: url,
      responseType: "blob",
    })
      .then((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          const base64data = reader.result;
          callback(base64data);
        };
      })
      .catch((error) => console.error(error));
  }

  loadImage(Book.Image, function (dataUrl) {
    doc.addImage(dataUrl, "JPEG", imgOffset, currentY, imgWidth, imgHeight);
  });
  //doc.addImage(Book.Image, "JPEG", imgOffset, currentY, imgWidth, imgHeight);
  currentY += imgHeight + 5;
  // Ajout de la description et de la biographie
  doc.setFont("Irish Grover", "bold");
  doc.setFontSize(16);
  currentY = addMultilineText("Biographie", margin, currentY + 5);
  doc.setFont("Irish Grover", "normal");
  doc.setFontSize(12);
  Book.Biographie.forEach((line) => {
    currentY = addMultilineText(line, margin, currentY + 5);
  });
  doc.setFontSize(16);
  doc.setFont("Irish Grover", "bold");
  currentY = addMultilineText("Description", margin, currentY + 5);
  doc.setFont("Irish Grover", "normal");
  doc.setFontSize(12);
  Book.Description.forEach((line) => {
    currentY = addMultilineText(line, margin, currentY + 5);
  });

  // Téléchargement du PDF
  doc.save("book.pdf");
};

// generatePDF();

export default function BookCorp(props) {
  const [book,setBook] = useState(props.Book)
  console.log(props.Book);
  //************************************************* */
  const DescriptionContent = (props) => {
    return (
      <DescriptionContainer>
        <TitreE align={"left"} fontSize={props.TitleSize || 28}>
          {props.Desc.Title}
        </TitreE>
        {Array.isArray(props.Desc.Content) ? (
          props.Desc.Content.map((Content, index) => {
            return (
              <Descri fontSize={props.DescripSize || 16}>
                {Content.length < props.TextLength || 300
                  ? Content
                  : Content.slice(0, props.TextLength || 300) + "....."}
              </Descri>
            );
          })
        ) : (
          <div style={{ width: "fit-content", marginRight: "5px" }}>
            <Descri fontSize={props.DescripSize || 16}>
              {props.Desc.Content}
            </Descri>
          </div>
        )}
      </DescriptionContainer>
    );
  };
  // const ActionButton = () => {
  //   return (
  //     <div
  //       className="Bouton-div"
  //       style={{
  //         width: "40%",
  //         marginLeft: "0",
  //         marginRight: "auto",
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: "150px",
  //           margin: "auto",
  //           marginTop: "12px",
  //         }}
  //       >
  //         <Button
  //           txt={"Commander !"}
  //           func={(e) => {
  //             window.location.href = "/Home";
  //           }}
  //         />
  //       </div>
  //     </div>
  //   );
  // };
  // const ActionIcones = () => {
  //   return (
  //     <div
  //       className="Icones-Bouton-div"
  //       style={{
  //         width: "170px",
  //         marginLeft: "auto",
  //         marginRight: "0",
  //         borderRadius: "0 30px 0 0",
  //         display: "flex",
  //         flexDirection: "row",
  //       }}
  //     >
  //       <IconButton aria-label="share" size="large" style={{ margin: "10px" }}>
  //         <ShareIcon
  //           fontSize="inherit"
  //           onClick={(e) => {
  //             window.location.href = `https://www.linkedin.com/shareArticle?mini=true&url="http://localhost:3000/0"`;
  //           }}
  //         />
  //       </IconButton>
  //       <IconButton aria-label="delete" size="large" style={{ margin: "10px" }}>
  //         <DownloadIcon
  //           fontSize="inherit"
  //           onClick={(e) => {
  //             generatePDF(props.Book);
  //           }}
  //         />
  //       </IconButton>
  //     </div>
  //   );
  // };
  const RightBlock = () => {
    return (
      <RightContent>
        <PricingContainer>
          <Pricing align={"left"} fontSize={props.TitleSize || 28}>
            {"Prix : " + props.Book.Prix + " €"}
          </Pricing>
          <Disponibility
            style={{}}
            align={"left"}
            fontSize={props.TitleSize || 18}
          >
            {"Disponibilité : " + props.Book.Disponibilite}
          </Disponibility>
        </PricingContainer>
        <DescriptionContent
          Desc={{
            Title: "Description",
            Content: props.Book.Description,
          }}
          TextLength={400}
        />
      </RightContent>
    );
  };
  const LeftBlock = () => {
    const divStyle = {
      width: "80%",
      marginLeft: "auto",
      marginRight: "10px",
      display: "flex",
      flexDirection: "row",
    };
    return (
      <LeftContent>
        <DescriptionContent
          Desc={{
            Title: "Biographie",
            Content: props.Book.Biographie,
          }}
          TitleSize={20}
          DescripSize={14}
        />
        <div style={divStyle}>
          <DescriptionContent
            Desc={{
              Title: "Auteur",
              Content: props.Book.Auteur,
            }}
            TitleSize={20}
            DescripSize={14}
          />
          <DescriptionContent
            Desc={{
              Title: "Categorie",
              Content: props.Book.Categorie,
            }}
            TitleSize={20}
            DescripSize={14}
          />
        </div>
      </LeftContent>
    );
  };
  // const isMobile = useMediaQuery(theme.breakpoints.down("300px"));
  // const TopActionContent = ({
  //   onShareClick,
  //   onDownloadClick,
  //   onOrderClick,
  // }) => {
  //   const theme = useTheme();
  //   const isMobile = false;
  //   const [anchorEl, setAnchorEl] = useState(null);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   if (isMobile) {
  //     // Affichage pour les écrans de moins de 300px
  //     return (
  //       <div>
  //         <IconButton aria-label="actions" size="large" onClick={handleClick}>
  //           {/* Utilisez une icône appropriée ici pour indiquer un menu */}
  //         </IconButton>
  //         <Menu
  //           id="actions-menu"
  //           anchorEl={anchorEl}
  //           open={Boolean(anchorEl)}
  //           onClose={handleClose}
  //         >
  //           <MenuItem
  //             onClick={() => {
  //               handleClose();
  //               onOrderClick();
  //             }}
  //           >
  //             Commander !
  //           </MenuItem>
  //           <MenuItem
  //             onClick={() => {
  //               handleClose();
  //               onShareClick();
  //             }}
  //           >
  //             Partager
  //           </MenuItem>
  //           <MenuItem
  //             onClick={() => {
  //               handleClose();
  //               onDownloadClick();
  //             }}
  //           >
  //             Télécharger
  //           </MenuItem>
  //         </Menu>
  //       </div>
  //     );
  //   } else {
  //     // Affichage pour les écrans de plus de 300px
  //     return (
  //       <TopActionContent>
  //         <ActionButton onOrderClick={onOrderClick} />
  //         <ActionIcones
  //           onShareClick={onShareClick}
  //           onDownloadClick={onDownloadClick}
  //         />
  //       </TopActionContent>
  //     );
  //   }
  // };
  return (
    <Body>
      <NavBar />
      <RightHectorContainer />
      <Content>
        <TopImgDiv>
          <TopImgContainer>
            <StyledImage src={props.Book.Image} alt="Description de l'image" />
          </TopImgContainer>
          <TitleContainer className="TitleContainer" style={{}}>
            <TitreE align={"center"}>{props.Book.Titre}</TitreE>
            <TitreE align={"center"} color={"#D58936"}>
              {" "}
              .Con
            </TitreE>
          </TitleContainer>
        </TopImgDiv>
        <WhiteContainer>
          <ActionTopDiv>
            <TopActionPart>
              {console.log("le props sera "+book)}
              <ActionButtons book={book}/>
              {/* <TopActionContent /> <Devider /> */}
              <Devider />
            </TopActionPart>
          </ActionTopDiv>
          <ContentWriting>
            <LeftBlock />
            <RightBlock />
          </ContentWriting>
        </WhiteContainer>
      </Content>
    </Body>
  );

  //******************************************** */
}
