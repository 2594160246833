import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CheckoutContext } from "../../CheckOutContext";
import Button from "@mui/material/Button";
export default function AddressForm({ handleNext }) {
  const { shippingDetails, setShippingDetails } = useContext(CheckoutContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails({ ...shippingDetails, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    handleNext();
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Adresse de livraison
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="Prénom"
              fullWidth
              autoComplete="given-name"
              variant="standard"
              value={shippingDetails.firstName || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Nom"
              fullWidth
              autoComplete="family-name"
              variant="standard"
              value={shippingDetails.lastName || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              label="Adresse"
              fullWidth
              autoComplete="shipping address-line1"
              variant="standard"
              value={shippingDetails.address1 || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address2"
              label="Complément d'adresse"
              fullWidth
              autoComplete="shipping address-line2"
              variant="standard"
              value={shippingDetails.address2 || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="Ville"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              value={shippingDetails.city || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="postalCode"
              label="Code postal"
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
              value={shippingDetails.postalCode || ""}
              onChange={handleChange}
            />
          </Grid>

          {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox color="secondary" name="saveAddress" value="yes" />
            }
            label="Use this address for payment details"
          />
        </Grid> */}
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem",
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            Suivant
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
}
