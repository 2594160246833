import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loadCartItems, clearCart } from "../slices/cartSlice"; // Importez également clearCart

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [User, setUser] = useState(null);
  const dispatch = useDispatch();
  const fetchUser = async () => {
    try {
      const res = await axios.post(
        "https://www.editionspointcon.fr/api/Utilisateur/GetDataUser",
        {
          email: "Mail",
          mdp: "mdp",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (res.status === 200) {
        const User = {
          id: res.data.id,
          id_User: res.data.id_User,
          UserName: res.data.UserName,
          email: res.data.email,
          Preferences: res.data.Preferences,
        };
        setUser(User);
      } else if (res.status === 201) {
        setUser(null);
        dispatch(clearCart());
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données d'utilisateur:",
        error
      );
    }
  };

  const fetchCart = async () => {
    try {
      const res = await axios.get(
        "https://www.editionspointcon.fr/api/Utilisateur/cart",

        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      // alert("res.status " + res.status);
      console.log("le cart recu :")
      console.log(res.data.cartItems);
      if (res.status === 200) {
        // alert("on a le 200");
        dispatch(loadCartItems(res.data.cartItems));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du panier:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (User) {
      fetchCart();
    }
  }, [User, dispatch]);

  return (
    <UserContext.Provider value={{ User, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
