// import React from "react";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";

const ButtonStyle = styled(Button)(
  ({
    width,
    height,
    bordercolor,
    backgroundcolor,
    textcolor,
    borderradius,
  }) => ({
    boxShadow: "none",
    textTransform: "none",
    width: width || "100%",
    height: height || "100%",
    marginRight:10,
    marginLeft: "auto",
    fontSize: 14,
    padding: "6px 12px",
    lineHeight: 1.5,
    border: `solid 2px ${bordercolor || "#1C1E2A"}`,
    borderRadius: borderradius || "35px",
    backgroundColor: backgroundcolor || "#1C1E2A",
    color: textcolor || "White",
    fontFamily: "Irish Grover",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: backgroundcolor || "#1C1E2A",
      boxShadow: "none",
    },
  })
);

export default function ButtonStyled(props) {
  return (
    <ButtonStyle
      variant="contained"
      disableRipple
      bordercolor={props.bordercolor}
      backgroundcolor={props.backgroundcolor}
      textcolor={props.textcolor}
      borderradius={props.borderradius}
      width={props.width}
      height={props.height}
      onClick={props.func}
    >
      {props.txt}
    </ButtonStyle>
  );
}
