import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Button,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // pour le bouton du menu déroulant
import { useUser } from "../useUser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { addToCart } from "../../slices/cartSlice";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const ActionButtons = (props) => {
  console.log("on a recu le props " + props.book.id);
  const { User } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  // const { items: products, status } = useSelector((state) => state.products);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseD = () => {
    setOpen(false);
  };

  const handleLoginRedirect = () => {
    handleCloseD();
    window.location.href = "/Login";
  };
  const dispatch = useDispatch();

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isConnected = (user) => {
    try {
      return user && user.id_User != null; // Vérifie si user.id_User existe
    } catch (error) {
      return false;
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const commonButtonStyle = {
    height: "40px",
    margin: "5px",
    padding: "0 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "white",
  };

  if (isMobile) {
    // Affichage pour les écrans mobiles
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center", // Centre les éléments verticalement
          justifyContent: "center", // Centre les éléments horizontalement
          height: "75%", // Prend 75% de la hauteur
        }}
      >
        <IconButton
          aria-label="add to cart"
          onClick={() => {
            if (isConnected(User)) {
              handleAddToCart(props.book);
              // toast.success("Correctement ajouté à votre panier !");
            } else {
              handleClickOpen();
            }
          }}
        >
          <ShoppingCartIcon />
        </IconButton>
        <IconButton
          aria-label="order"
          onClick={() => {
            if (isConnected(User)) {
              handleAddToCart(props.book);
              // toast.success("Correctement ajouté à votre panier !");
            } else {
              handleClickOpen();
            }
          }}
        >
          <PaymentIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton aria-label="favorite">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="download">
          <DownloadIcon />
        </IconButton>
      </div>
    );
  } else {
    // Desktop view
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "95%",
          height: "55%",
          minHeight: "70px",
        }}
      >
        {/* Button container */}
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            // startIcon={<ShoppingCartIcon color="primary" />}
            color="success"
            style={commonButtonStyle}
            onClick={() => {
              if (isConnected(User)) {
                handleAddToCart(props.book);
                // toast.success("Correctement ajouté à votre panier !");
              } else {
                handleClickOpen();
              }
            }}
          >
            Ajouter au panier
          </Button>

          <Dialog
            open={open}
            onClose={handleCloseD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirmation requise"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Vous devez vous connecter pour pouvoir acheter !
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseD} color="primary">
                Annuler
              </Button>
              <Button onClick={handleLoginRedirect} color="primary" autoFocus>
                Se connecter
              </Button>
            </DialogActions>
          </Dialog>
{/* 
          <ToastContainer /> */}
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <IconButton aria-label="like">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="download">
            <DownloadIcon />
          </IconButton>
        </div>
      </div>
    );
  }
};

export default ActionButtons;
