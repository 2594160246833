import React from "react";
import { styled } from "@mui/material/styles";
import Button from "../Composant/BoutonStyled";
import Box from "@mui/material/Box";
import NavBar from "../Composant/NavBar";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import axios from "axios";
import { showToast } from '../Composant/toastNotifications';

const Content = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));
const TextPart = styled("div")(({ theme }) => ({
  width: "60%",
  margin: "auto",
  height: "180px",
  textAlign: "center",
  paddingTop: "50px",
  [theme.breakpoints.down("635")]: {
    paddingTop: "40px",
    height: "160px",
  },
  [theme.breakpoints.down("557")]: {
    paddingTop: "30px",
    height: "fit-content",
    width: "fit-content",
  },
}));
const Title = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 48,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("880")]: { fontSize: 36 },
  [theme.breakpoints.down("400")]: { fontSize: 28 },
}));

const TitleSub = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 28,
  fontWeight: 200,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  marginTop: "20px",
  [theme.breakpoints.down("880")]: { fontSize: 22 },
  [theme.breakpoints.down("401")]: { marginTop: "0px", fontSize: 18 },
}));
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-outlined": {
    transform: "translate(-50%, -50%) scale(1)", // Garder le label centré
    left: "50%",
    top: "50%",
    pointerEvents: "none", // Le label ne doit pas intercepter les clics
    backgroundColor: "transparent", // Aucun fond pour ne pas cacher le champ de saisie
  },
  "& .MuiInputLabel-shrink": {
    display: "none", // Cacher le label quand le TextField est focus
  },
  "& .MuiOutlinedInput-input": {
    textAlign: "center", // Centrer le texte saisi
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 20,
    "& fieldset": {
      border: "solid 3px black",
    },
    "&:hover fieldset": {
      border: "solid 3px black",
    },
    "&.Mui-focused fieldset": {
      border: "solid 3px black",
    },
  },
  "& input": {
    height: "25px",
    color: "black", // couleur du texte saisi
    fontFamily: "Irish Grover",
    fontSize: 20,
    fontWeight: 700,
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontFamily: "Irish Grover", // Appliquer la police au placeholder
    // Autres styles spécifiques au placeholder, si nécessaire
    opacity: 1,
  },
  width: "100%",
  margin: "auto",
  marginTop: "10px",
  [theme.breakpoints.down("760")]: {
    "& input": {
      fontSize: 16,
    },
  },
}));
const FormContainer = styled("div")(({ theme }) => ({
  width: "70%",
  margin: "auto",
  marginTop: "20px",
  [theme.breakpoints.down("837")]: { marginTop: "20px" },
  [theme.breakpoints.down("600")]: { width: "90%" },
}));
const LinkContainer = styled("div")(({ theme }) => ({
  width: "fit-content",
  margin: "auto",
  marginTop: "50px",
  [theme.breakpoints.down("600")]: { width: "90%" },
}));
const TextFieldContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "80px",
  margin: "auto",
  maxWidth: "500px",
  minWidth: "250px",
}));
const CustomLink = styled(Link)(({ theme }) => ({
  fontFamily: "Irish Grover",
  fontSize: "18px",
  color: "#52FF00",
  textDecoration: "none", // Enlève le soulignement
  "&:hover": {
    textDecoration: "underline", // Ajoute le soulignement au survol, si souhaité
  },
  [theme.breakpoints.down("600")]: { fontSize: "16px" },
}));
export default function Register() {
  const [Mail, setMail] = React.useState("");
  const [mdp, setPassword] = React.useState("");
  const [UserName, setUserName] = React.useState("");

  const handleRegister = async () => {
    async function createUser(Mail, mdp, UserName) {
      try {
        const response = await axios.post(
          "https://www.editionspointcon.fr/api/Utilisateur/User",
          {
            email: Mail,
            mdp: mdp,
            UserName: UserName,
            Preference: "001",
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true
          }
        );

        // console.log(response.status);
        if (response.status === 400) {
          console.log(response.data.error);
        }
        if (response.status === 201) {
          // alert(response.data.message);
          showToast('success', response.data.message);
          // Gérer la connexion réussie ici, comme enregistrer le token et rediriger l'utilisateur
          window.location.href = "/books";
        } else {
          // alert("Échec de la connexion: " + response.data.error);
          showToast('error', "Échec de la connexion");
        }
      } catch (error) {
        console.error("Erreur lors de la création de l'utilisateur:", error);
      }
    }

    // Utilisation de la fonction...
    createUser(Mail, mdp, UserName);
  };
  //******************************** */
  const TitlePart = () => {
    return (
      <TextPart>
        <div
          style={{
            display: "flex",
            width: "fit-content",
            flexDirection: "row",
            margin: "auto",
          }}
        >
          <Title align={"center"}>Editions</Title>
          <Title align={"center"} color={"#D58936"}>
            {" "}
            .Con
          </Title>
        </div>
        <TitleSub align={"center"}>Creez votre compte </TitleSub>
      </TextPart>
    );
  };
  //************************************************* */
  return (
    <div>
      <Content>
        <NavBar />
        <TitlePart />
        <FormContainer>
          <TextFieldContainer>
            <CustomTextField
              placeholder="Votre Nom d'utilisateur"
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              value={UserName}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <CustomTextField
              placeholder="Votre Email"
              onChange={(e) => {
                setMail(e.target.value);
              }}
              value={Mail}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <CustomTextField
              placeholder="Mot de passe"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={mdp}
            />
          </TextFieldContainer>
          <div style={{ width: "fit-content", height: "50px", margin: "auto" }}>
            <Button
              width={220}
              height={50}
              txt={"Creer compte"}
              Click={0}
              func={handleRegister}
            />
          </div>
          <LinkContainer>
            <CustomLink href="Login" variant="body2">
              {"Vous etes utilisateur ? Connectez vous !"}
            </CustomLink>
          </LinkContainer>
        </FormContainer>
      </Content>
    </div>
  );
  //******************************************** */
}
