import React, { useState, useEffect } from "react";
/*import { useState, useContext } from "react";
import LangueContext from "../LangueContext";*/
import { styled } from "@mui/system";
import NavBar from "../Composant/NavBar";
// import { useBooks } from "../useBook";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import {
  TextField,
  Button,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

const Content = styled("div")(({ theme }) => ({
  height: "fit-content",
}));
const BlocBooks = styled("div")(({ theme }) => ({
  maxWidth: "85%", // Utilisation de maxWidth au lieu de mmaxWidth
  width: "100%", // Pour occuper tout l'espace disponible
  margin: "auto",
  display: "flex", // Utilisation de flex pour centrer les éléments horizontalement et verticalement
  flexWrap: "wrap", // Pour que les éléments se mettent en ligne si nécessaire
  justifyContent: "center", // Centre les éléments horizontalement
  alignItems: "center", // Centre les éléments verticalement
  gap: "10px", // Espacement entre les éléments

  // Suppression de gridTemplateColumns
  // Vous n'avez pas besoin de spécifier de colonnes dans ce cas.

  [theme.breakpoints.down("1435")]: {
    maxWidth: "85%", // Modifier également la maxWidth pour chaque breakpoint
  },
  [theme.breakpoints.down("1100")]: {
    maxWidth: "85%",
  },
  [theme.breakpoints.down("700")]: {
    maxWidth: "85%",
  },
}));
const HectorLeftContainer = styled("div")(({ theme }) => ({
  width: "15%",
  height: "100%",
  minWidth: "100px",
  minHeight: "100px",
  maxHeight: "200px",
  position: "absolute", // Positionnement absolu
  top: 0, // Alignement en haut
  left: 15, // Alignement à gauche
  zIndex: -1, // Mettre en arrière-plan
  backgroundImage: "url('/Group 46.png')",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top left",
  [theme.breakpoints.down("500")]: {
    height: "100px",
    minHeight: "100px",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-outlined": {
    transform: "translate(-50%, -50%) scale(1)", // Garder le label centré
    left: "50%",
    top: "50%",
    pointerEvents: "none", // Le label ne doit pas intercepter les clics
    backgroundColor: "transparent", // Aucun fond pour ne pas cacher le champ de saisie
  },
  "& .MuiInputLabel-shrink": {
    display: "none", // Cacher le label quand le TextField est focus
  },
  "& .MuiOutlinedInput-input": {
    textAlign: "left", // Centrer le texte saisi
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 20,
    "& fieldset": {
      border: "solid 3px black",
    },
    "&:hover fieldset": {
      border: "solid 3px black",
    },
    "&.Mui-focused fieldset": {
      border: "solid 3px black",
    },
  },
  "& input": {
    height: "25px",
    color: "black", // couleur du texte saisi
    fontFamily: "Irish Grover",
    fontSize: 20,
    fontWeight: 700,
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontFamily: "Irish Grover", // Appliquer la police au placeholder
    // Autres styles spécifiques au placeholder, si nécessaire
    opacity: 1,
  },
  width: "100%",
  margin: "auto",
  marginTop: "10px",
  [theme.breakpoints.down("760")]: {
    "& input": {
      fontSize: 16,
    },
  },
}));

const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    textAlign: "left",
    fontFamily: "Irish Grover",
    fontWeight: 700,
  },
  "& .MuiSelect-icon": {
    color: "black",
  },
});
const CustomOutlinedInput = styled(OutlinedInput)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "3px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "3px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "3px",
  },
  borderRadius: 20,
  backgroundColor: "white",
  fontFamily: "Irish Grover",
});
const CustomFormControl = styled(FormControl)({
  width: "100%",
  marginTop: "10px",
  "& .MuiInputLabel-outlined": {
    fontFamily: "Irish Grover",
  },
  "& .MuiInputLabel-shrink": {
    backgroundColor: "white",
    padding: "0 4px",
  },
});
const CustomMenuItem = styled(MenuItem)({
  fontFamily: "Irish Grover",
  fontWeight: 700,
});
const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  justifyItems: "center",
}));

const TopContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "520px",
  paddingTop: "10px",
  // backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  position: "relative",
  alignItems: "center", // Alignement vertical des éléments
  justifyContent: "space-between", // Espace équitable entre les éléments
  [theme.breakpoints.down("621")]: { height: "740px" },
}));
const TopSpanContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "20px auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  justifyItems: "center",
  alignItems: "center",
}));
const StyledCard = styled(Card)(({ theme, width, height }) => ({
  position: "relative",
  width: width || 220,
  height: height || 260,
  margin: "10px",
  overflow: "hidden",
  borderRadius: "25px",
  border: `solid 3px black`,
  alignSelf: "center",
  "&:hover": { cursor: "pointer" },
}));
const ButtonStyle = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  width: "100%",
  height: "100%",
  marginRight: 10,
  marginLeft: "auto",
  fontSize: 18,
  padding: "6px 12px",
  lineHeight: 1.5,
  border: `solid 2px #1C1E2A`,
  borderRadius: "35px",
  backgroundColor: "#1C1E2A",
  color: "White",
  fontFamily: "Irish Grover",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#1C1E2A",
    boxShadow: "none",
  },
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  height: "55px",
  width: "40%",
  margin: "auto",
}));
const FilterContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "20px auto",
  display: "grid",
  justifyContent: "center",
  justifyItems: "center",
  alignItems: "center",
  gridGap: "10px",
  gridTemplateColumns: "repeat(2, 1fr)",
  [theme.breakpoints.down("621")]: {
    gridTemplateColumns: "repeat(1, 1fr)",
    width: "80%",
  },
}));
const PartFilterContainer = styled("div")(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
}));
const TitleTopContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "fit-content",
  flexDirection: "row",
  margin: "auto",
  height: "150px",
  paddingTop: "20px",
  [theme.breakpoints.down("635")]: { height: "100px" },
  [theme.breakpoints.down("635")]: { height: "80px", paddingTop: "10px" },
}));
const PartPriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "flex",
  alignItems: "center",
  width: "100%",
  margin: "auto",
  marginTop: "10px",
  borderRadius: 20,
  marginBottom: "10px",
  border: "3px solid black",
  height: "58px",
}));
const CardContentContainer = styled("div")(({ theme }) => ({
  paddingTop: "10px",
  height: "50px",
  display: "flex",
  width: "fit-content",
  flexDirection: "row",
  margin: "auto",
}));
const Title = styled(Box)(({ theme, color }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 32,
  fontWeight: 700,
  lineHeight: "40px",
  textAlign: "left",
  marginRight: "10px",
  [theme.breakpoints.down("760")]: { fontSize: 16 },
}));
const TitleTop = styled(Box)(({ theme, color }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 48,
  fontWeight: 700,
  lineHeight: "40px",
  textAlign: "left",
  marginRight: "10px",
  [theme.breakpoints.down("900")]: { fontSize: 36 },
  [theme.breakpoints.down("650")]: { fontSize: 28 },
  [theme.breakpoints.down("560")]: { fontSize: 24 },
  [theme.breakpoints.down("460")]: { fontSize: 18 },
}));
const Text = styled(Box)(({ theme }) => ({
  fontFamily: "Irish Grover",
  color: "black",
  fontSize: 20,
  fontWeight: 700,
  lineHeight: "40px",
  textAlign: "left",
  marginLeft: "10px",
  [theme.breakpoints.down("760")]: { fontSize: 16 },
}));
const StyledSlider = styled(Slider)({
  "& .MuiSlider-rail": {
    height: "8px",
    borderRadius: "4px",
    backgroundColor: "#e0e0e0",
  },
  "& .MuiSlider-track": {
    height: "8px",
    borderRadius: "4px",
    backgroundColor: "black", // ou votre couleur primaire
  },
  "& .MuiSlider-thumb": {
    height: "24px",
    width: "24px",
    backgroundColor: "white",
    border: "3px solid black", // pour correspondre à vos champs de saisie
    "&:hover": {
      boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.16)",
    },
    "& .Mui-active": {
      boxShadow: "0px 0px 0px 14px rgba(0, 0, 0, 0.16)",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: "1.2",
    fontSize: "1rem",
    background: "unset",
    padding: "0",
    width: "32px",
    height: "32px",
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#f0f0f0",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
const StyledCardMedia = styled(CardMedia)({
  width: "100%",
  height: "90%",
});
const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  fontFamily: "Irish Grover",
  fontSize: 20,
  fontWeight: 700,
  color: "black",
  [theme.breakpoints.down("760")]: { fontSize: 16 },
}));
export default function Books() {
  //************************************************* */

  const [Titre, setTitre] = useState("");
  const [genre, setGenre] = useState("");
  const [prix, setPrix] = useState([10, 50]);
  const [note, setNote] = useState("");
  const [format, setFormat] = useState("");
  const [disponibilite, setDisponibilite] = useState("");
  const [filteredBooks, setfilteredBooks] = useState([]);

  const [books, setBooks] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);

  // const { books } = useBooks();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://www.editionspointcon.fr/api/Utilisateur/Histoire/GetHistoires?page=${
            page + 1
          }&limit=${rowsPerPage}`
        );

        if (response.status === 200) {
          const books_list = response.data.data.map((book, index) => {
            console.log("1********************************");
            console.log(page);
            console.log(rowsPerPage);
            console.log(book);
            console.log(book.Images.length);
            console.log("2********************************");
            return {
              id: book._id,
              Image:
                "https://www.editionspointcon.fr/" +
                book.Images[book.Images.length - 2], //book.Images[0], // Prend la première image, ajustez selon les besoins
              Titre: book.Titre,
              Description:
                book.Chapitres.length > 0
                  ? book.Chapitres.length > 1
                    ? book.Chapitres[0].slice(1, 4)
                    : book.Chapitres[0].slice(0, 4)
                  : [
                      "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer",
                    ],
              Biographie:
                book.Resume.length > 0
                  ? [book.Resume.slice(0, 3)]
                  : [
                      "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer",
                    ],
              Auteur: book.Auteur,
              Categorie: book.Category,
              Prix: book.Prix,
              Note: book.Note,
              Format: book.Format,
              Disponibilite: book.Disponibilite,
            };
          });
          setBooks(books_list);
          setTotalRows(response.data.total);
          filterBooks(books_list);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des livres:", error);
      }
    };

    fetchData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    filterBooks(books);
  }, [books, Titre, genre, prix, note, format, disponibilite, page]); // Ajoutez `page` comme dépendance

  const numPages = Math.ceil(totalRows / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    console.log("Changing page to:", newPage);
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Réinitialisez à la première page
  };

  const handlePrixChange = (event, newValue) => {
    setPrix(newValue);
  };

  const filterBooks = (booksToFilter) => {
    const filtered = booksToFilter.filter((book) => {
      return (
        (Titre !== ""
          ? book.Titre.toLowerCase().includes(Titre.toLowerCase())
          : true) &&
        (genre !== ""
          ? book.Categorie.toLowerCase() === genre.toLowerCase()
          : true) &&
        (prix !== "" ? book.Prix >= prix[0] && book.Prix <= prix[1] : true) &&
        (note !== "" ? book.Note === note : true) &&
        (format !== "" ? book.Format === format : true) &&
        (disponibilite !== "" ? book.Disponibilite === disponibilite : true)
      );
    });
    setfilteredBooks(filtered); // Mettez à jour l'état des livres filtrés
  };

  const TitlePart = () => {
    return (
      <TitleTopContainer>
        <TitleTop align={"center"}>Trouvez votre livre</TitleTop>
        <TitleTop align={"center"} color={"#D58936"}>
          {" "}
          .Con
        </TitleTop>
      </TitleTopContainer>
    );
  };
  const CardBook = (props) => {
    return (
      <StyledCard
        width={props.width}
        height={props.height}
        onClick={(e) => {
          window.location.href = props.Link;
        }}
      >
        <CardContentContainer>
          <Title align={"center"}>{props.Titre}</Title>
          <Title align={"center"} color={"#D58936"}>
            {/* {props.Titre.slice(props.Titre.length - 4, props.Titre.length)} */}
            .Con
          </Title>
        </CardContentContainer>
        <StyledCardMedia component="img" image={props.Image} alt="Card image" />
      </StyledCard>
    );
  };

  return (
    <Content>
      <NavBar />
      <TopContainer>
        <HectorLeftContainer />
        <TopSpanContainer>
          <TitlePart />
          <FilterContainer>
            <PartFilterContainer>
              <CustomTextField
                placeholder="Trouver un livre"
                onChange={(e) => setTitre(e.target.value)}
                value={Titre}
                style={{ width: "100%", marginBottom: "10px" }}
              />
              <CustomFormControl
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                <InputLabelStyled>Genre</InputLabelStyled>
                <CustomSelect
                  value={genre}
                  onChange={(e) => setGenre(e.target.value)}
                  input={<CustomOutlinedInput />}
                >
                  <CustomMenuItem value="">Aucun</CustomMenuItem>
                  <CustomMenuItem value="Fiction">Fiction</CustomMenuItem>
                  <CustomMenuItem value="Science">Science</CustomMenuItem>
                  <CustomMenuItem value="Humouristic">
                    Humouristic
                  </CustomMenuItem>
                  {/* Ajouter d'autres genres ici */}
                </CustomSelect>
              </CustomFormControl>
              <CustomFormControl fullWidth style={{ marginBottom: "10px" }}>
                <InputLabelStyled>Note</InputLabelStyled>
                <CustomSelect
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  input={<CustomOutlinedInput />}
                >
                  <CustomMenuItem value="">Aucune</CustomMenuItem>
                  <CustomMenuItem value="1">★☆☆☆☆</CustomMenuItem>
                  <CustomMenuItem value="2">★★☆☆☆</CustomMenuItem>
                  <CustomMenuItem value="3">★★★☆☆</CustomMenuItem>
                  <CustomMenuItem value="4">★★★★☆</CustomMenuItem>
                  <CustomMenuItem value="5">★★★★★</CustomMenuItem>
                </CustomSelect>
              </CustomFormControl>
            </PartFilterContainer>
            <PartFilterContainer>
              <PartPriceContainer>
                <Text align={"center"}>Prix</Text>
                <StyledSlider
                  value={prix}
                  onChange={handlePrixChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  style={{ width: "80%", margin: "0 10%" }} // Respectez les marges pour l'alignement
                />
              </PartPriceContainer>
              <CustomFormControl fullWidth style={{ marginBottom: "10px" }}>
                <InputLabelStyled>Format</InputLabelStyled>
                <CustomSelect
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                  input={<CustomOutlinedInput />}
                >
                  <CustomMenuItem value="">Aucun</CustomMenuItem>
                  <CustomMenuItem value="Poche">Poche</CustomMenuItem>
                  <CustomMenuItem value="Livre">Livre</CustomMenuItem>
                  {/* Ajouter d'autres formats ici */}
                </CustomSelect>
              </CustomFormControl>
              <CustomFormControl fullWidth style={{ marginBottom: "10px" }}>
                <InputLabelStyled>Disponibilité</InputLabelStyled>
                <CustomSelect
                  value={disponibilite}
                  onChange={(e) => setDisponibilite(e.target.value)}
                  input={<CustomOutlinedInput />}
                >
                  <CustomMenuItem value="">Indifférent</CustomMenuItem>
                  <CustomMenuItem value="Immediate">Immediate</CustomMenuItem>
                  <CustomMenuItem value="Sur commande">
                    Sur commande
                  </CustomMenuItem>
                  {/* Ajouter d'autres options de disponibilité ici */}
                </CustomSelect>
              </CustomFormControl>
            </PartFilterContainer>
          </FilterContainer>
          {/* <ButtonContainer>
            <ButtonStyle> Rechercher</ButtonStyle>
          </ButtonContainer> */}
        </TopSpanContainer>
      </TopContainer>

      <MainContainer>
        <BlocBooks key={page}>
          {console.log("bloc books rendred " + filteredBooks)}
          {filteredBooks.map((book, index) => {
            return (
              <div key={book.id}>
                {" "}
                {/* Utilisez book.id comme clé */}
                <CardBook
                  width={280}
                  height={320}
                  Image={book.Image}
                  Titre={book.Titre}
                  Link={"/" + book.id}
                />
              </div>
            );
          })}
        </BlocBooks>
      </MainContainer>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px" }}
      >
        <Pagination
          count={numPages}
          page={page + 1}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </Content>
  );

  //******************************************** */
}
