import React from "react";
/*import { useState, useContext } from "react";
import LangueContext from "../LangueContext";*/
import { styled } from "@mui/system";
import NavBar from "../Composant/NavBar";
import Carousel from "../Composant/Carousel";
import Carousel2 from "../Composant/CarouselLivre";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
const Content = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100%",
}));
const TopContent = styled("div")(({ theme }) => ({
  width: "100%",
  height: "300px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start", // Aligner les enfants en haut
  justifyContent: "flex-start",
  [theme.breakpoints.down("1175")]: { height: "250px" },
  [theme.breakpoints.down("482")]: { height: "160px" },
  [theme.breakpoints.down("370")]: { height: "180px" },
}));
const HectorLeftContainer = styled("div")(({ theme }) => ({
  width: "15%",
  height: "100%",
  minWidth: "100px",
  minHeight: "100px",
  marginTop: "0",
  marginBottom: "auto",
  backgroundImage: "url('/Group 46.png')",
  backgroundSize: "contain", // Garde le ratio de l'image
  backgroundRepeat: "no-repeat", // Empêche la répétition de l'image
  backgroundPosition: "top left",

  [theme.breakpoints.down("500")]: {
    height: "100px",
    minHeight: "100px",
  },
}));
const HectorRightContainer = styled("div")(({ theme }) => ({
  width: "15%",
  height: "100%",
  marginTop: "0",
  marginRight: 0,
  marginLeft: "auto",
  minWidth: "100px",
  minHeight: "150px",
  maxHeight: "300px",
  marginBottom: "auto",
  backgroundImage: "url('/Group 49.png')",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top right",
  position: "relative", // Ajout de la position relative
  zIndex: -1, // Pour placer l'image en arrière-plan
  [theme.breakpoints.down("500")]: { minWidth: "70px" },
}));
const TopCarouselContainer = styled("div")(({ theme }) => ({
  width: "80%",
  height: "80%",
  marginLeft: "10px",
  marginTop: "20px",
  [theme.breakpoints.down("500")]: { width: "70%" },
  [theme.breakpoints.down("482")]: { height: "160px" },
  [theme.breakpoints.down("370")]: { width: "60%" },
}));

const BotContent = styled("div")(({ theme }) => ({
  width: "95%",
  height: "350px",
  minHeight: "350px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "left",
  alignItems: "left",
  [theme.breakpoints.down("630")]: { height: "540px" },
  [theme.breakpoints.down("400")]: { height: "430px" },
  [theme.breakpoints.down("370")]: { height: "300px" },
}));

const BotContentContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
}));

const ButtonStyle = styled(Button)(({theme}) => ({
  boxShadow: "none",
  textTransform: "none",
  width: "100%",
  height: "100%",
  marginRight: 10,
  marginLeft: "auto",
  fontSize: 14,
  padding: "6px 12px",
  lineHeight: 1.5,
  border: `solid 2px #1C1E2A`,
  borderRadius: "35px",
  backgroundColor: "#1C1E2A",
  color: "White",
  fontFamily: "'Manrope', sans-serif",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#1C1E2A",
    boxShadow: "none",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  height: "45px",
  width: "160px",
  margin: "auto",
}));
const TextContainer = styled("div")(({ theme }) => ({
  height: "90%",
  width: "fit-content",
  minWidth: "180px",
  marginLeft: "5%",
}));
const MidleContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "70px",
  padding: "10px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  justifyItems: "flex-start",
}));
const BotCarouselContainer = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  margin: "auto",
}));
const Title = styled(Box)(({ theme, fontSize, color, align, lineH }) => ({
  fontFamily: "Irish Grover",
  color: color || "black",
  fontSize: 32,
  fontWeight: 700,
  lineHeight: lineH || "40px",
  textAlign: align || "left",
  marginRight: "10px",
  [theme.breakpoints.down("1250")]: { fontSize: 20 },
  [theme.breakpoints.down("1060")]: { fontSize: 28 },
  [theme.breakpoints.down("770")]: { fontSize: 24 },
  [theme.breakpoints.down("680")]: { fontSize: 20 },
  [theme.breakpoints.down("630")]: { fontSize: 28 },
  [theme.breakpoints.down("460")]: { fontSize: 22 },
  [theme.breakpoints.down("400")]: { fontSize: 16 },
  [theme.breakpoints.down("200")]: { fontSize: 14 },
}));

const Component1 = () => (
  <div
    style={{
      width: "400px",
      height: "220px",
      backgroundImage: "url('/Frame 48.png')",
      backgroundSize: "100% 100%",
    }}
  ></div>
);

export default function Books() {
  //************************************************* */
  const components = [
    Component1,
    Component1,
    Component1,
    Component1,
    Component1,
    Component1,
  ];
  return (
    <Content>
      <NavBar />
      <MidleContainer>
        {" "}
        <TextContainer>
          <Title align={"center"}>Oh des citacons !!</Title>
        </TextContainer>
      </MidleContainer>
      <TopContent>
        <HectorLeftContainer />
        <TopCarouselContainer>
          <Carousel components={components} />
        </TopCarouselContainer>
      </TopContent>
      <div
        style={{
          marginTop: "20px",
          width: "80%",
          height: "3px",
          backgroundColor: "#D8D8D8",
          margin: "auto",
        }}
      ></div>
      <MidleContainer>
        {" "}
        <TextContainer>
          <Title align={"center"}>Les dernieres sortis</Title>
        </TextContainer>
        <HectorRightContainer />
      </MidleContainer>
      <BotContent>
        <BotContentContainer>
          <BotCarouselContainer>
            <Carousel2 />
          </BotCarouselContainer>
        </BotContentContainer>
      </BotContent>

      <ButtonContainer>
        <ButtonStyle onClick={(e)=>{
          window.location.href="/books"
        }}> Voir plus de livre !</ButtonStyle>
      </ButtonContainer>

      <div style={{ height: "40px" }}></div>
    </Content>
  );

  //******************************************** */
}
