import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useUser } from "../useUser";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { showToast } from './toastNotifications';
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: "Irish Grover",
  fontWeight: "bold",
  fontSize: "18px",
  color: "black",
  margin: theme.spacing(1),
}));

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const cart = useSelector((state) => state.cart);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const { User } = useUser();
  const handleLogout = async () => {
    const response = await axios.get(
      "https://www.editionspointcon.fr/api/Utilisateur/LogOut",

      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.status === 200) {
      showToast('success', response.data.message);
      window.location.href = "/Login";
    } else {
      showToast('error', 'Échec de la deconnexion');
    }
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isConnected = (user) => {
    try {
      return user && user.id_User != null; // Vérifie si user.id_User existe
    } catch (error) {
      return false;
    }
  };
  const menuItems = [
    { text: "Home", link: "/home" },
    { text: "About", link: "/about" },
    { text: "Contact", link: "/contact" },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          background: "transparent",
          boxShadow: "0px 1px 5px 1px rgba(0, 0, 0, .1)",
        }}
      >
        <StyledToolbar>
          {/* Logo à gauche */}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontFamily: "Irish Grover" }}
          >
            <img
              src="/LogoEdition.png"
              alt="Logo"
              style={{ width: "150px", "&:hover": { cursor: "pointer" } }}
              onClick={() => (window.location.href = "/")}
            />
          </Typography>

          {/* Liens de navigation au milieu */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            {menuItems.map((item, index) => (
              <StyledButton
                key={index}
                onClick={() => (window.location.href = item.link)}
              >
                {item.text}
              </StyledButton>
            ))}
          </Box>

          {/* Icônes de profil et panier à droite */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {isConnected(User) ? (
              <IconButton color="inherit" onClick={handleLogout}>
                {/* Icône ou élément à afficher lorsque l'utilisateur est connecté */}

                <LogoutIcon />
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                onClick={() => (window.location.href = "/Login")}
              >
                {/* Icône ou élément à afficher lorsque l'utilisateur n'est pas connecté */}
                <LoginIcon />
              </IconButton>
            )}
            <IconButton
              color="inherit"
              onClick={() => (window.location.href = "/cart")}
            >
              <Badge badgeContent={cart.cartTotalQuantity} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Box>

          {/* Icône de menu pour les écrans plus petits */}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        </StyledToolbar>
      </AppBar>

      {/* Menu déroulant pour les écrans plus petits */}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleMenuClose();
              window.location.href = item.link;
            }}
          >
            {item.text}
          </MenuItem>
        ))}
        <MenuItem onClick={handleMenuClose}>
          <AccountCircleIcon sx={{ marginRight: 1 }} />
          Profile
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ShoppingCartIcon sx={{ marginRight: 1 }} />
          Cart
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default NavBar;
